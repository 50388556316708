import React, {
  useCallback,
  useEffect,
  useState,
  useMemo,
  useRef,
} from 'react';
import Cards, { Focused } from 'react-credit-cards-2';
import { AiOutlineLoading } from 'react-icons/ai';
import { FiAlertTriangle, FiArrowLeft } from 'react-icons/fi';

import logoPlan from 'assets/logo-plans.png';
import axios from 'axios';
import Card from 'components/Card';
import CardSkeleton from 'components/CardSkeleton';
import Checkbox from 'components/Checkbox';
import Input from 'components/Input';
import Modal from 'components/Modal';
import PDFTermsOfService from 'components/PDFTermsOfService';
import Select from 'components/Select';
import Toast from 'components/Toast';
import { format, parse } from 'date-fns';
import {
  Form,
  Formik,
  FormikErrors,
  FormikHandlers,
  FormikHelpers,
  setNestedObjectValues,
} from 'formik';
import { api, apiMyIpac } from 'services/api';
import { formatMoney, formatPercentage } from 'utils/format';
import { competenceMask } from 'utils/mask';
import * as Yup from 'yup';
import 'react-credit-cards-2/dist/es/styles-compiled.css';

/* interface Price {
  type: string;
  value: number;
  valuePerMonth: number;
}

interface Plan {
  uuid?: string;
  id: number;
  title: string;
  description: string;
  min_collaborators: number;
  max_collaborators: number;
  price: number;
  active: number;
  created_at: string;
  updated_at: string;
  values: Price[];
} */

interface Representative {
  name: string;
  email: string;
  phone: string;
  cpf: string;
  rg: string;
  // birthdate: string;
  responsibility_id: string;
  cep: string;
  uf: string;
  city: string;
  address: string;
}

interface Company {
  id?: number;
  name: string;
  email: string;
  phone: string;
  cnpj: string;
  cep: string;
  uf: string;
  city: string;
  address: string;
  street: string;
  number: string;
  uuid?: string;
}

/* Dados vindos da api de cadastrar company */
interface CompanyData {
  id: string;
  uuid: string;
  document: string;
  address: string;
  cep: string;
  city: string;
  cnpj: string;
  email: string;
  name: string;
  phone: string;
  uf: string;
  number: string;
  street: string;
  license_id: number;
  representative_id: number;
  email_confirmation_code: string;
  created_at: string;
  updated_at?: string;
}

interface License {
  period_id: number;
  plan_id?: number;
  amount: number;
  promotional_code_id?: number;
}

interface PromotionalCode {
  id: number;
  code: string;
  discount_rate: number;
  discount_rate_formatted: string;
  start_date: string;
  end_date: string;
}

/* interface Period {
  id: number;
  period: string;
  discount_rate: number;
  totalMonth: number;
  total: number;
  typePayment?: string;
} */

interface FormData {
  representative: Representative;
  company: Company;
  license: License;
  terms_of_service: boolean;
  uuid?: string;
}

interface FormDataCode {
  code: string;
}

interface ViaCepResponse {
  cep: string;
  logradouro: string;
  complemento?: string;
  bairro: string;
  localidade: string;
  uf: string;
  ibge?: string;
  gia?: string;
  ddd: string;
  siafi?: string;
  erro?: boolean;
}

interface CreditCardPreviweProps {
  number: string;
  expiry: string;
  cvc: string;
  name: string;
}

interface Plan {
  id: number;
  uuid: string;
  name: string;
  description: string;
  min_collaborators: number;
  max_collaborators: number;
  price: string;
  active: boolean;
  created_at: string;
  updated_at: string;
}

export interface LicenseGroups {
  amount: number;
  unit_value: string;
  unit_value_formatted: string;
  total: string;
  total_formatted: string;
}

interface Period {
  id: number;
  period: string;
  discount_rate: number | null;
  created_at: string;
  updated_at: string;
  value_uni_formatted: string;
  value_per_month: string;
  value_per_month_formatted: string;
  total_discount: string;
  total_discount_formatted: string | null;
  original_value: string;
  original_value_formatted: string;
  total_value: string;
  total_value_formatted: string;
  original_total_value: string;
  original_total_value_formatted: string;
  license_groups: LicenseGroups[];
  installments: number;
  typePayment?: string;
}

interface Signature {
  plan: Plan;
  periods: Period[];
  promotional_code?: PromotionalCode;
}

interface Subscription {
  uuid: string;
  title: string;
  description: string;
  bullets: { description: string }[];
  active: false;
  values: {
    type: string;
    value: number;
    valuePerMonth: number;
  }[];
  acceptPaymentMethods: string[];
}

const Plans: React.FC = () => {
  const appliedDiscountRef = useRef(0);
  // const formSimulateRef = useRef<FormikHandlers>(null);
  const [plan, setPlan] = useState<Plan>();
  const [promotionalCodeInfo, setPromotionalCodeInfo] =
    useState<PromotionalCode>();
  const [buyer, setBuyer] = useState<CompanyData>();
  // const [simulate, setSimulate] = useState<Signature>();
  const [periodsPrice, setPeriodsPrice] = useState<Period[]>([]);
  const [representative, setRepresentative] = useState<any>();
  const [openWarningExistingUser, setOpenWarningExistingUser] = useState(false);
  const [open, setOpen] = useState(false);
  const [openCode, setOpenCode] = useState(false);
  const [openPayment, setOpenPayment] = useState(false);
  // const [periods, setPeriods] = useState<Period[]>([]);
  const [selectedPeriod, setSelectedPeriod] = useState<Period>({} as Period);
  const [amountLicences, setAmountLicences] = useState(5);
  const [existingEmail, setExistingEmail] = useState('');

  const [sendModal, setSendModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [loadingResendCode, setLoadingResendCode] = useState(false);
  const [loadingCompanyCep, setLoadingCompanyCep] = useState(false);
  const [loadingValue, setLoadingValue] = useState(true);
  const [promotionCode, setPromotionCode] = useState('');
  const [collaboratorsAmount, setCollaboratorsAmount] = useState(0);
  const [togglePromotionCode, setTogglePromotionCode] = useState(false);
  const [successMsg, setSuccessMsg] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [errorSimualtion, setErrorSimulation] = useState(false);
  const [step, setStep] = useState(0);

  const [cardName, setCardName] = useState('');
  const [cardNumber, setCardNumber] = useState('');
  const [cardExpiry, setCardExpiry] = useState('');
  const [cardCVC, setCardCVC] = useState('');
  const [focus, setFocus] = useState<Focused>('name');
  const [subscription, setSubscription] = useState<Subscription>();

  const [contractBlob, setContractBlob] = useState<Blob>();
  const [verifiedEmail, setVerifiedEmail] = useState(false);

  // const [token, setToken] = useState();

  const date = new Date();
  const currentDate = format(date, 'yyyy-MM-dd');

  type setValue = (
    value: any,
    shouldValidate?: boolean | undefined,
  ) => Promise<void | FormikErrors<FormData>>;

  const initialData: FormData = {
    // 'representative.address': 'ipac',
    representative: {
      // birthdate: '',
      cpf: '',
      email: '',
      name: '',
      phone: '',
      responsibility_id: '',
      rg: '',
      cep: '',
      uf: '',
      city: '',
      address: '',
    },

    company: {
      address: '',
      cep: '',
      city: '',
      cnpj: '',
      email: '',
      name: '',
      phone: '',
      uf: '',
      number: '',
      street: '',
    },

    license: {
      amount: 5,
      period_id: 1,
      plan_id: 1,
    },
    terms_of_service: false,
  };

  const initialDataCode: FormDataCode = {
    code: '',
  };

  const initialDataCard: CreditCardPreviweProps = {
    number: '',
    expiry: '',
    cvc: '',
    name: '',
  };

  const states = [
    { label: 'AC', value: 'AC' },
    { label: 'AL', value: 'AL' },
    { label: 'AP', value: 'AP' },
    { label: 'AM', value: 'AM' },
    { label: 'BA', value: 'BA' },
    { label: 'CE', value: 'CE' },
    { label: 'DF', value: 'DF' },
    { label: 'ES', value: 'ES' },
    { label: 'GO', value: 'GO' },
    { label: 'MA', value: 'MA' },
    { label: 'MS', value: 'MS' },
    { label: 'MT', value: 'MT' },
    { label: 'MG', value: 'MG' },
    { label: 'PA', value: 'PA' },
    { label: 'PB', value: 'PB' },
    { label: 'PR', value: 'PR' },
    { label: 'PE', value: 'PE' },
    { label: 'PI', value: 'PI' },
    { label: 'RJ', value: 'RJ' },
    { label: 'RN', value: 'RN' },
    { label: 'RS', value: 'RS' },
    { label: 'RO', value: 'RO' },
    { label: 'RR', value: 'RR' },
    { label: 'SC', value: 'SC' },
    { label: 'SP', value: 'SP' },
    { label: 'SE', value: 'SE' },
    { label: 'TO', value: 'TO' },
  ];

  const responsibility = [
    { label: 'Sócio', value: '1' },
    { label: 'Gestor', value: '2' },
    { label: 'Técnico', value: '3' },
  ];

  useEffect(() => {
    setLoadingValue(true);

    const { CancelToken } = axios;
    const source = CancelToken.source();

    apiMyIpac
      .get<Signature>('subscriptions/plans/simulate', {
        params: {
          qty_collaborators: amountLicences,
          promotional_code: promotionCode,
        },
        cancelToken: source.token,
      })
      .then((response) => {
        const { plan: bestPlan, periods, promotional_code } = response.data;
        /* console.log(data);
          console.log(
            'data',
            data.periods
              .filter((period) => period.id !== 1)
              .map((resp) => resp),
          ); */
        setPlan(bestPlan);
        // setSimulate(data);
        setPeriodsPrice(
          periods
            .filter((period) => period.id !== 1)
            .map((per) => ({
              ...per,
              value_per_month_formatted: formatMoney(per.value_per_month),
              total_value_formatted: formatMoney(per.total_value),
              original_total_value_formatted: formatMoney(
                per.original_total_value,
              ),
              total_discount_formatted: per.total_discount
                ? formatPercentage(per.total_discount)
                : null,
            })),
        );
        setLoadingValue(false);

        if (promotional_code && promotional_code.discount_rate) {
          setPromotionalCodeInfo({
            ...promotional_code,
            discount_rate_formatted: formatPercentage(
              promotional_code.discount_rate,
            ),
          });

          if (!appliedDiscountRef.current) {
            setSuccessMsg(
              `Desconto de ${formatPercentage(promotional_code.discount_rate)} aplicado com sucesso`,
            );
            setSendModal(true);

            setTimeout(() => {
              setSendModal(false);
            }, 3500);
          }

          appliedDiscountRef.current = 1;
        } else {
          setPromotionalCodeInfo(undefined);

          appliedDiscountRef.current = 0;
        }
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          setLoadingValue(true);
          return;
        }

        setErrorMsg(
          error?.response?.data.error || 'Erro ao enviar o formulário',
        );

        setErrorModal(true);

        setTimeout(() => {
          setErrorModal(false);
        }, 3500);

        setLoadingValue(false);
      });
    // .finally(() => setLoadingValue(false));
    return () => {
      /* Após chamar novamente a api, sem que se tenha
          resposta da primeira solicitação (pendente)
          o pedido anterior é cancelado e se realiza um novo pedido */
      source.cancel('Operação cancelada pelo usuário');
    };
  }, [amountLicences, promotionCode]);

  const handleSimulatePlan = useCallback(
    (amount: number, promoCode?: string) => {
      if (amount < 5) {
        setAmountLicences(5);
        setPromotionCode(promoCode || '');
        return;
      }
      setAmountLicences(amount);
      setPromotionCode(promoCode || '');
    },
    [],
  );

  const handleVerifyEmail = useCallback(
    async (email: string, resetForm: any) => {
      if (!email) {
        return;
      }

      apiMyIpac
        .get<boolean>('users/verify', {
          params: {
            email,
          },
        })
        .then((resp) => {
          const existEmail = resp.data;

          setVerifiedEmail(true);

          if (!existEmail) {
            return;
          }

          setExistingEmail(email);
          /* Abre modal avisando sobre a existência da conta e recomendando
        realizar a aquisição das licenças pelo MyIPAC */
          resetForm();
          setOpen(false);
          setStep(0);

          setOpenWarningExistingUser(true);
        });
    },
    [],
  );

  const handleTypePayment = useCallback((idPeriod: number) => {
    // caso for 1 ou 2 vai printar Semester
    switch (idPeriod) {
      case 1:
      case 2:
        return 'SEMESTER';
      case 3:
        return 'YEARLY';

      default:
        return 'SEMESTER';
    }
  }, []);

  /* Necessário renderizar uma vez para setar os valores no estado utilizado
  para exibir as informações de licenças no PDF */
  // useEffect(
  //   () => handlePrice(Number(amountLicences)),
  //   [handlePrice, amountLicences],
  // );

  function validateCPF(cpfInput?: string) {
    if (!cpfInput) return true;

    const cpf = cpfInput.replace(/[^0-9]+/g, '');

    let soma;
    let resto;

    soma = 0;

    if (cpf === '00000000000') return false;

    for (let i = 1; i <= 9; i += 1) {
      soma += Number(cpf.substring(i - 1, i)) * (11 - i);
    }
    resto = (soma * 10) % 11;

    if (resto === 10 || resto === 11) resto = 0;
    if (resto !== Number(cpf.substring(9, 10))) return false;

    soma = 0;
    for (let i = 1; i <= 10; i += 1) {
      soma += Number(cpf.substring(i - 1, i)) * (12 - i);
    }
    resto = (soma * 10) % 11;

    if (resto === 10 || resto === 11) resto = 0;
    if (resto !== Number(cpf.substring(10, 11))) return false;

    return true;
  }

  const schemaTermsOfUse = useMemo(
    () =>
      Yup.object().shape({
        terms_of_service: Yup.boolean()
          .isTrue('Você deve aceitar os termos antes de prosseguir')
          // .oneOf([true], )
          .required('Você deve aceitar os termos antes de prosseguir'),
      }),
    [],
  );

  const schemaCompany = useMemo(
    () =>
      Yup.object().shape({
        representative: Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
          email: Yup.string()
            .required('Email obrigatório')
            .email('Digite um email válido!'),
          phone: Yup.string().required('Telefone obrigatório'),
          cpf: Yup.string()
            .required('CPF obrigatório')
            .test({
              name: 'validate-cpf',
              test(value, ctx) {
                if (!validateCPF(value?.toString())) {
                  return ctx.createError({
                    message:
                      'CPF inválido verifique a informação e tente novamente.',
                  });
                }
                return true;
              },
            }),

          rg: Yup.string()
            .max(11, 'O RG deve possuir no máximo 11 caracteres')
            .required('RG obrigatório'),
          responsibility_id: Yup.string().required(
            'Selecione o seu cargo na empresa',
          ),
        }),

        company: Yup.object().shape({
          name: Yup.string().required('Nome da empresa obrigatório'),
          email: Yup.string()
            .required('Email da empresa obrigatório')
            .email('Digite um email válido!'),
          phone: Yup.string().required('Telefone da empresa obrigatório'),
          cnpj: Yup.string().required('CNPJ da empresa obrigatório'),
          cep: Yup.string().required('CEP da empresa obrigatório'),
          uf: Yup.string().required('Selecione o estado da empresa'),
          city: Yup.string().required('Cidade da empresa obrigatória'),
          address: Yup.string().required('Endereço da empresa obrigatório'),
          number: Yup.string().required('Número do endereço é obrigatório'),
        }),
      }),
    [],
  );

  const schemasValidation = [schemaCompany, schemaTermsOfUse];

  const schemaCreditCard = useMemo(
    () =>
      Yup.object().shape({
        name: Yup.string().required('Nome obrigatório'),
        number: Yup.string().required('Número do cartão obrigatório'),
        expiry: Yup.string().required('Data de vencimento obrigatória'),
        cvc: Yup.string().required('Código obrigatório'),
      }),
    [],
  );

  const getCompanyCep = useCallback((values: any, setValues: setValue) => {
    setLoadingCompanyCep(true);
    const { cep: cepComp } = values.company;
    if (!cepComp) return;

    const cep = cepComp.replace(/\D+/g, '');

    if (!cep || cep.length !== 8) return;

    axios
      .get<ViaCepResponse>(`https://viacep.com.br/ws/${cep}/json/`)
      .then((resp) => {
        const { bairro, logradouro, complemento, uf, erro } = resp.data;

        if (erro) {
          return;
        }

        /* setAddress(`${logradouro}`);
          setCity(bairro);

          setCompanyUf(uf);
          setCompanyAddress(logradouro);
          setCompanyCity(bairro); */

        // console.log(resp.data);

        setValues({
          ...values,
          company: {
            ...values.company,
            city: bairro,
            uf,
            address: `${logradouro || ''}  ${complemento ? `, ${complemento}` : ''}`,
          },
        });
      })
      .catch(() => {
        // console.log('error');
      })
      .finally(() => setLoadingCompanyCep(false));
  }, []);

  const handleResendCode = useCallback(() => {
    setLoadingResendCode(true);

    api
      .get('companies/send-code', {
        params: {
          representative_id: representative?.id,
        },
      })
      .then(() => {
        setSuccessMsg(
          'Novo código enviado ao e-mail, verifique também a caixa de spam se for necessário',
        );
        setSendModal(true);

        setTimeout(() => {
          setSendModal(false);
        }, 3500);
      })
      .catch(() => {
        setErrorMsg('Erro ao enviar o código ao e-mail');
        setErrorModal(true);

        setTimeout(() => {
          setErrorModal(false);
        }, 3500);
      })
      .finally(() => {
        setLoadingResendCode(false);
      });
  }, [representative]);

  const handleSubmit = useCallback(
    async (data: FormData, actions: FormikHelpers<FormData>) => {
      try {
        // Para previnir preenchimentos automáticos que não acionem o evento onChange no input
        /* if (!verifiedEmail) {
          handleVerifyEmail(data.representative.email, actions.resetForm);
          setVerifiedEmail(false);
          return;
        } */

        const { cep, cnpj, phone } = data.company;
        const {
          cpf,
          phone: representativePhone,
          cep: representativeCep,
        } = data.representative;

        const formattedData: FormData = {
          ...data,
          license: {
            ...data.license,
            amount: amountLicences,
            plan_id: data.license.plan_id,
          },
          company: {
            ...data.company,
            cep: cep.replace(/\D+/g, ''),
            cnpj: cnpj.replace(/\D+/g, ''),
            phone: phone.replace(/\D+/g, ''),
            street: data.company.address,
          },
          representative: {
            ...data.representative,
            cep: representativeCep.replace(/\D+/g, ''),
            cpf: cpf.replace(/\D+/g, ''),
            phone: representativePhone.replace(/\D+/g, ''),
          },
        };
        const respCompBuyer = await api.post<CompanyData>(
          'companies',
          formattedData,
        );
        setBuyer(respCompBuyer.data);
        setRepresentative({
          id: respCompBuyer.data.representative_id,
          uuid_buyer: respCompBuyer.data.uuid,
          name: data.representative.name,
          email: data.representative.email,
          cpf: data.representative.cpf,
          rg: data.representative.rg,
          phone: data.representative.phone,
        });
        document.body.style.overflow = 'auto';

        setSuccessMsg('Formulário enviado com sucesso!');
        setSendModal(true);

        setTimeout(() => {
          setSendModal(false);
        }, 3500);

        setCollaboratorsAmount(amountLicences);
        actions.resetForm();
        setStep(step + 1);
        setOpenCode(true);
      } catch (error: any) {
        // console.log(error);
        setErrorMsg(
          error?.response?.data.error || 'Erro ao enviar o formulário',
        );

        setErrorModal(true);

        setTimeout(() => {
          setErrorModal(false);
        }, 3500);
      } finally {
        actions.setSubmitting(false);
      }
    },
    [step, amountLicences],
  );

  const handleSubmitCode = useCallback(
    async (data: FormDataCode, actions: FormikHelpers<FormDataCode>) => {
      // console.log(data);

      // Validar código gerado e enviado para o email
      try {
        const responseCode = await api.get(`validate/${representative?.id}`, {
          params: { code: data.code },
        });
        if (responseCode.data) {
          setStep(step + 1);

          setOpenPayment(true);

          setSuccessMsg('Código validado com sucesso! Prossiga com sua compra');
          setSendModal(true);

          setTimeout(() => {
            setSendModal(false);
          }, 3500);
          actions.resetForm();
        } else {
          setErrorMsg(
            'Código incorreto, copie e cole ou digite o código que foi enviado em seu e-mail',
          );

          setErrorModal(true);

          setTimeout(() => {
            setErrorModal(false);
          }, 3500);
        }
      } catch (error) {
        setErrorMsg(
          'Código incorreto, copie e cole ou digite o código que foi enviado em seu e-mail',
        );

        setErrorModal(true);

        setTimeout(() => {
          setErrorModal(false);
        }, 3500);
      } finally {
        actions.setSubmitting(false);
      }
    },
    [step, representative?.id],
  );

  const handleCreateSubscription = useCallback(
    async (data: CreditCardPreviweProps): Promise<string> => {
      const expiry = data.expiry.split('/');

      if (expiry.length < 2 || Number(expiry[0]) > 12) {
        throw new Error('Verifique a data de vencimento novamente');
      }

      let formatDateExpiry = '';

      try {
        formatDateExpiry = format(
          parse(data.expiry, 'MM/yy', new Date()),
          'MM/yyyy',
        );
      } catch (error) {
        throw new Error(
          'A data de vencimento deve corresponder ao formato MM/AA',
        );
      }

      try {
        const subscriptionData = {
          uuidPlan: plan?.uuid,
          basicValue: {
            type: selectedPeriod.typePayment,
            // o valor por mes deve ser o valor inteiro pois o sistema da barte
            // divide conforme o tipo de pagamento
            valuePerMonth: selectedPeriod ? selectedPeriod.value_per_month : 0,
          },
          // valor obrigatorio mesmo na documentação falando q n é,
          // optamos por colocar os valores minimos
          additionalValue: {
            value: '1', // (valor adicional) deve ser no minimo 0,01 sendo que o aceito foi 1
            installments: '1', // (Parcela) min: 1
          },
          payment: {
            method: 'CREDIT_CARD',
            card: {
              holderName: data.name.toLocaleUpperCase(),
              number: data.number,
              expiration: formatDateExpiry,
              cvv: data.cvc,
            },
            fraudData: {
              document: buyer?.cnpj,
              name: buyer?.name,
              email: buyer?.email,
              phone: buyer?.phone,
              billingAddress: {
                country: buyer?.uf,
                state: buyer?.city,
                city: buyer?.city,
                district: buyer?.address,
                street: buyer?.address,
                zipCode: buyer?.cep,
                number: buyer?.number.toString(),
                complement: buyer?.address,
              },
            },
          },
          uuidBuyer: buyer?.uuid,
          startDate: currentDate,
          company_id: buyer?.id,
          amount_license: amountLicences,
          price: selectedPeriod.value_per_month,
        };

        const resp = await api.post<Subscription>(
          'subscription',
          subscriptionData,
        );
        const { uuid } = resp.data;

        setSubscription(resp.data);

        setSuccessMsg('Pagamento realizado com sucesso!');
        setSendModal(true);

        setTimeout(() => {
          setSendModal(false);
        }, 3500);

        return uuid;
      } catch (error) {
        // console.log(error);
        // Apagar ao realizar deploy
        // console.log(error);

        throw new Error('Erro nos dados do cartão, tente novamente');
      }
    },
    [amountLicences, buyer, currentDate, plan, selectedPeriod],
  );

  const handleCreateDefaultStructure = useCallback(
    async (subscriptionUUID: string): Promise<void> => {
      try {
        const dataFile = new FormData();

        if (!buyer) return;
        if (!selectedPeriod) return;

        dataFile.append('company[cnpj]', buyer?.cnpj);
        dataFile.append('company[company_name]', buyer?.name);
        dataFile.append('company[name]', buyer?.name);
        dataFile.append('company[email]', buyer?.email);
        dataFile.append('company[phone]', buyer?.phone);
        dataFile.append('company[cep]', buyer?.cep);
        dataFile.append('company[uf]', buyer?.uf);
        dataFile.append('company[city]', buyer?.city);
        dataFile.append('company[address]', buyer?.address);

        dataFile.append(
          'representative[uuid_buyer]',
          representative?.uuid_buyer,
        );
        dataFile.append('representative[name]', representative?.name);
        dataFile.append('representative[email]', representative?.email);
        dataFile.append('representative[cpf]', representative.cpf);
        dataFile.append('representative[rg]', representative.rg);
        dataFile.append('representative[phone]', representative.phone);

        dataFile.append('subscription[uuid_barte]', subscriptionUUID);

        if (plan?.id) {
          dataFile.append(
            'subscription[subscription_plan_id]',
            plan.id.toString(),
          );
        }

        dataFile.append(
          'subscription[subscription_period_id]',
          selectedPeriod.id.toString(),
        );
        dataFile.append('subscription[amount]', amountLicences.toString());
        dataFile.append('subscription[contract]', contractBlob || '');

        // console.log('sessao:', response.data);
        await apiMyIpac.post('default-structure', dataFile);

        setSuccessMsg(
          'Ambiente MyIPAC cadastrado com sucesso! Verifique seu e-mail.',
        );
        setSendModal(true);
        setTimeout(() => {
          setSendModal(false);
        }, 3500);
      } catch (error) {
        // Apagar ao realizar deploy
        // console.log(error);

        throw new Error(
          'Erro ao cadastrar estrutura padrão, Entre em contato conosco',
        );
      }
    },
    [amountLicences, buyer, contractBlob, plan, representative, selectedPeriod],
  );

  const handleSubmitPayment = useCallback(
    async (
      data: CreditCardPreviweProps,
      actions: FormikHelpers<CreditCardPreviweProps>,
    ) => {
      try {
        let subscriptionUUID = subscription?.uuid || '';

        if (!subscriptionUUID) {
          subscriptionUUID = await handleCreateSubscription(data);
        }

        await handleCreateDefaultStructure(subscriptionUUID);

        actions.resetForm();

        setOpen(false);
        setOpenCode(false);
        setOpenPayment(false);
        setStep(0);
        setSubscription(undefined);

        /* Restaura a rolagem padrão da página inicial que é executa normalmente no param
        onClose do componente Modal */
        document.body.style.overflow = 'auto';
      } catch (error: any) {
        setErrorMsg(error?.message);

        setErrorModal(true);

        setTimeout(() => {
          setErrorModal(false);
        }, 3500);
      } finally {
        actions.setSubmitting(false);
      }
    },
    [subscription, handleCreateSubscription, handleCreateDefaultStructure],
  );

  return (
    <section className="container-custom mt-[-1px]">
      {/* <img
        src={vectorSquareCut}
        alt="vectores"
        className="absolute top-[-246px] left-[-120px]"
      /> */}
      <div className="flex justify-center mb-[43px]">
        <img src={logoPlan} alt="" className="w-auto h-20 lg:h-[121px] z-10" />
      </div>

      {sendModal && (
        <Toast
          status="success"
          title={successMsg}
          onClose={() => setSendModal(false)}
        />
      )}

      {errorModal && (
        <Toast
          status="error"
          title={errorMsg}
          onClose={() => setErrorModal(false)}
        />
      )}
      {errorSimualtion && (
        <Toast
          status="warning"
          title="Erro ao realizar simulação, revise os valores e tente novamente."
          onClose={() => setErrorSimulation(false)}
        />
      )}

      <Modal
        open={openWarningExistingUser}
        onClose={() => {
          // resetForm();
          setOpenWarningExistingUser(false);
        }}
        title="Você já possui uma conta MyIPAC"
      >
        <div className="flex flex-col justify-center items-center overflow-auto max-h-full">
          <div className="flex mb-4 rounded-sm bg-yellow-100 p-4">
            <FiAlertTriangle className="text-yellow-700 mr-4" size={20} />
            <p className="font-inter text-sm font-light text-yellow-700 leading-6">
              Esse site tem o propósito apenas de realização da primeira compra.
              Para gerenciar as suas licenças já adquiridas ou para adquirir
              novas realize o login no MyIPAC e acesse o menu de licenças.
            </p>
          </div>
          <a
            href={`${process.env.REACT_APP_WEB_MYIPAC}/signin/${existingEmail}`}
            type="submit"
            className="text-white bg-blue-500 hover:bg-blue-600 rounded-lg p-2 h-10 ml-2"
          >
            Acessar conta MyIPAC
          </a>
        </div>
      </Modal>

      <Formik
        // innerRef={formSimulateRef}
        initialValues={{
          amount: '5',
          promo_code: '',
        }}
        onSubmit={(values) => {
          // console.log(values);
          handleSimulatePlan(Number(values.amount), values.promo_code);
        }}
      >
        {({ handleSubmit: handleSubmitFormik, setFieldValue }) => (
          <Form>
            <div className="flex flex-col items-center container mx-auto">
              <div className="flex justify-center">
                <p className="text-4xl text-center">
                  Simule o melhor plano para a sua empresa
                </p>
              </div>
              <div className="flex justify-center items-center mt-8 flex-col">
                <div className="flex mb-4 items-center  w-full justify-between">
                  <p className="text-xl whitespace-nowrap">
                    Quantidade de Colaboradores:
                  </p>
                  <div className="ml-2.5">
                    <Input
                      name="amount"
                      min={5}
                      max={10000000}
                      type="number"
                      width="28"
                      margin="0px"
                      onChange={() => {
                        handleSubmitFormik();
                      }}
                    />
                  </div>
                </div>
                <div className="flex mb-4 items-center justify-between w-full h-10">
                  <div className="flex items-center">
                    <p className="text-xl text-nowrap">Código Promocional:</p>
                    <label className="switch ml-3">
                      <input
                        type="checkbox"
                        onChange={() => {
                          setTogglePromotionCode(!togglePromotionCode);

                          if (togglePromotionCode) {
                            setFieldValue('promo_code', '');
                            handleSubmitFormik();
                          }
                        }}
                      />
                      <span className="slider round" />
                    </label>
                  </div>

                  <div className="flex items-center">
                    {togglePromotionCode && (
                      <Input
                        /* onChange={(el) => {
                      //handleSimulatePlan(amountLicences, el.target.value);
                    }} */
                        name="promo_code"
                        type="text"
                        width="28"
                        margin="0"
                      />
                    )}
                  </div>
                </div>
                {togglePromotionCode && (
                  <button
                    type="submit"
                    className="text-white bg-blue-500 hover:bg-blue-600 rounded-lg p-2 h-10 ml-2"
                  >
                    Calcule com o desconto
                  </button>
                )}
              </div>
            </div>
          </Form>
        )}
      </Formik>

      <Formik
        initialValues={initialData}
        onSubmit={(values, actions) => {
          // console.log(values);
          handleSubmit(values, actions);
        }}
        validationSchema={schemasValidation[step]}
      >
        {({
          errors,
          touched,
          isSubmitting,
          setValues,
          values,
          validateForm,
          setTouched,
          resetForm,
          // setFieldValue,
        }) => (
          <Form>
            <div className="flex flex-col lg:flex-row justify-center items-center mt-6">
              {!loadingValue ? (
                <>
                  {periodsPrice
                    // .filter((p) => p.id > 1)
                    .map((period) => (
                      <Card
                        key={period.id}
                        title={period.period}
                        totalPricePromoCode={
                          period.original_total_value !== period.total_value
                            ? period.total_value_formatted
                            : ''
                        }
                        totalPrice={period.original_total_value_formatted}
                        price={period.value_per_month_formatted}
                        // description={}
                        onClick={() => {
                          // Ao fechar e abrir o modal novamente a assinatura será descartada
                          setSubscription(undefined);

                          if (step === 2) {
                            setOpenCode(true);
                          } else {
                            setOpen(true);
                          }

                          setSelectedPeriod({
                            ...period,
                            typePayment: handleTypePayment(period.id),
                            license_groups: period.license_groups.map(
                              (group) => ({
                                ...group,
                                unit_value_formatted: formatMoney(
                                  group.unit_value,
                                ),
                                total_formatted: formatMoney(group.total),
                              }),
                            ),
                            total_discount_formatted: period.discount_rate
                              ? formatPercentage(period.total_discount)
                              : null,
                          });
                          // setSelectedPlan(period);
                        }}
                        name="license.plan_id"
                      />
                    ))}
                </>
              ) : (
                <div className="flex flex-col lg:flex-row">
                  <CardSkeleton />
                  <CardSkeleton />
                </div>
              )}

              <Modal
                open={open}
                onClose={() => {
                  // resetForm();
                  setOpen(false);
                  document.body.style.overflow = 'hidden';

                  setStep(0);
                }}
                title={
                  step === 0 ? 'Formulário de Contrato' : 'Verifique seus Dados'
                }
              >
                {step === 0 && (
                  <>
                    <div>
                      <div className="flex flex-col lg:flex-row container-custom justify-around pt-[40px] overflow-auto max-h-full">
                        {/* PESSOAL */}
                        <div className="flex flex-col">
                          <div className="flex justify-around">
                            <div className="bg-[#bee3f8] rounded-full w-[130px] h-[50px] flex justify-center items-center  ">
                              <p className="font-inter text-[20px] md:text-[25px]">
                                Pessoal
                              </p>
                            </div>
                          </div>

                          <Input
                            name="representative.name"
                            placeholder="Informe seu nome"
                            label="Nome"
                            id="name"
                            error={errors.representative?.name}
                            touched={touched.representative?.name}
                          />

                          <Input
                            name="representative.email"
                            placeholder="Informe seu e-mail"
                            label="E-mail"
                            id="email"
                            onBlurCustom={() =>
                              handleVerifyEmail(
                                values.representative.email,
                                resetForm,
                              )
                            }
                            error={errors.representative?.email}
                            touched={touched.representative?.email}
                          />

                          <Input
                            name="representative.phone"
                            label="Telefone"
                            placeholder="Informe seu telefone"
                            id="phone"
                            mask="phone"
                            touched={touched.representative?.phone}
                            error={errors.representative?.phone}
                          />

                          <Input
                            name="representative.cpf"
                            label="CPF"
                            placeholder="Informe seu CPF"
                            id="cpf"
                            mask="cpf"
                            error={errors.representative?.cpf}
                            touched={touched.representative?.cpf}
                          />

                          <Input
                            name="representative.rg"
                            label="Identidade (RG)"
                            placeholder="Informe seu RG"
                            id="rg"
                            touched={touched.representative?.rg}
                            error={errors.representative?.rg}
                            maxLength={11}
                          />

                          <Select
                            name="representative.responsibility_id"
                            id="responsibility"
                            placeholder="Selecione seu cargo"
                            label="Cargo na empresa"
                            options={responsibility.map((state) => ({
                              label: state.label,
                              value: state.value,
                            }))}
                            error={errors.representative?.responsibility_id}
                            touched={touched.representative?.responsibility_id}
                          />
                        </div>

                        <div className="hidden lg:block border-l-[1px] border-[#00000057]" />

                        {/* EMPRESA */}
                        <div className="flex flex-col">
                          <div className="flex justify-around">
                            <div className="bg-[#bee3f8] rounded-full w-[140px] h-[50px] flex justify-center items-center ">
                              <p className="font-inter text-[20px] md:text-[25px]">
                                Empresa
                              </p>
                            </div>
                          </div>

                          <Input
                            name="company.name"
                            label="Nome"
                            placeholder="Informe o nome"
                            id="companyName"
                            touched={touched.company?.name}
                            error={errors.company?.name}
                          />

                          <Input
                            name="company.email"
                            label="E-mail"
                            placeholder="Informe o e-mail"
                            id="companyEmail"
                            touched={touched.company?.email}
                            error={errors.company?.email}
                          />

                          <Input
                            name="company.phone"
                            label="Telefone"
                            placeholder="Informe o telefone"
                            id="companyPhone"
                            mask="phone"
                            error={errors.company?.phone}
                            touched={touched.company?.phone}
                          />

                          <Input
                            name="company.cnpj"
                            label="CNPJ"
                            placeholder="Informe o CNPJ"
                            id="cnpj"
                            mask="cnpj"
                            error={errors.company?.cnpj}
                            touched={touched.company?.cnpj}
                          />
                          <Input
                            name="company.cep"
                            label="CEP"
                            placeholder="Informe seu CEP"
                            id="cep"
                            onBlurCustom={() =>
                              getCompanyCep(values, setValues)
                            }
                            mask="cep"
                            touched={touched.company?.cep}
                            error={errors.company?.cep}
                          />
                          <Select
                            name="company.uf"
                            id="companyUf"
                            label="UF"
                            placeholder="Selecione o estado"
                            options={states.map((state) => ({
                              label: state.label,
                              value: state.value,
                            }))}
                            disabled={loadingCompanyCep}
                            error={errors.company?.uf}
                            touched={touched.company?.uf}
                          />
                          <Input
                            name="company.city"
                            label="Cidade"
                            placeholder="Informe a cidade"
                            id="companyCity"
                            // disabled={loadingCompanyCep}
                            error={errors.company?.city}
                            touched={touched.company?.city}
                          />
                          <Input
                            name="company.address"
                            label="Endereço"
                            placeholder="Informe o endereço"
                            id="companyAddress"
                            // disabled={loadingCompanyCep}
                            error={errors.company?.address}
                            touched={touched.company?.address}
                          />
                          {/* <Input
                            name="company.street"
                            label="Rua"
                            placeholder="Informe o endereço"
                            id="companyStreet"
                            // disabled={loadingCompanyCep}
                            error={errors.company?.street}
                            touched={touched.company?.street}
                          /> */}
                          <Input
                            name="company.number"
                            label="Número"
                            placeholder="Informe o número"
                            id="companyNumber"
                            type="number"
                            // disabled={loadingCompanyCep}
                            error={errors.company?.number}
                            touched={touched.company?.number}
                          />
                        </div>
                      </div>
                    </div>
                    {/* <div className="flex justify-center lg:justify-end">
                      <button
                        className={`bg-[#48BB78] w-[180px] h-[48px] rounded-md font-
                        text-[20px] hover:bg-[#2F855A] ${isSubmitting ? 'bg-[#2F855A]' : ''} `}
                        type="button"
                        onClick={async () => {
                          const validationErrors = await validateForm();
                          console.log(validationErrors);
                          if (Object.keys(validationErrors).length > 0) {
                            setTouched(
                              setNestedObjectValues(validationErrors, true),
                            );
                            return;
                          }
                          setStep(step + 1);
                        }}
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? (
                          <div className="flex justify-center">
                            <AiOutlineLoading className="animate-spin" />
                          </div>
                        ) : (
                          'Enviar'
                        )}
                      </button>
                    </div> */}
                  </>
                )}
                {step === 1 && (
                  <div>
                    <div className="flex flex-col lg:flex-row container-custom justify-around pt-[40px] overflow-auto max-h-full">
                      <div className="flex flex-col mb-4">
                        <p className="text-xl font-bold mb-5">
                          DADOS DO CLIENTE LICENCIANTE
                        </p>
                        <div className="flex">
                          <p className="font-semibold mr-2">Razão Social:</p>
                          <p>{values.company.name}</p>
                        </div>
                        <div className="flex">
                          <p className="font-semibold mr-2">CNPJ Nº:</p>
                          <p>{values.company.cnpj}</p>
                        </div>
                        <div className="flex">
                          <p className="font-semibold mr-2">Endereço:</p>
                          <p>{values.company.address}</p>
                        </div>
                        <div className="flex">
                          <p className="font-semibold mr-2">CEP:</p>
                          <p>{values.company.cep}</p>
                        </div>
                        <div className="flex mb-2">
                          <p className="font-semibold mr-2">
                            E-mail corporativo:
                          </p>
                          <p>{values.company.email}</p>
                        </div>
                        <div className="flex">
                          <p className="font-semibold mr-2">Representante:</p>
                          <p>{values.representative.name}</p>
                        </div>
                        <div className="flex">
                          <p className="font-semibold mr-2">CPF nº</p>
                          <p>{values.representative.cpf}</p>
                        </div>
                        <div className="flex">
                          <p className="font-semibold mr-2">Cel:</p>
                          <p>{values.company.phone}</p>
                        </div>
                        <div className="flex">
                          <p className="font-semibold mr-2">E-mail:</p>
                          <p>{values.representative.email}</p>
                        </div>
                      </div>
                      <div>
                        <p className="text-xl font-bold mb-5">
                          ORÇAMENTO - PRODUTO / SERVICOS / LICENÇAS
                        </p>
                        <div className="flex">
                          <p className="font-semibold mr-2">
                            Modalidade de Fidelização:
                          </p>
                          <p>{selectedPeriod?.period}</p>
                        </div>
                        <p className="font-semibold mr-2">
                          Descrição: MyIPAC – módulo GDP
                        </p>
                        <div className="flex mt-1">
                          <p className="font-semibold mr-2">
                            Quantidade de licenças:
                          </p>
                          <p>{amountLicences}</p>
                        </div>
                        <div className="flex flex-col mt-1">
                          {/* <p className="font-semibold mr-2 mb-2">Valor:</p> */}
                          <table>
                            <tbody>
                              {selectedPeriod.license_groups.map((lice) => (
                                <tr key={lice.unit_value}>
                                  <td>
                                    <p>
                                      {`${lice.amount} licenças de R$ ${lice.unit_value_formatted} = R$ ${lice.total_formatted}`}
                                    </p>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                        {!!promotionalCodeInfo?.discount_rate_formatted && (
                          <div className="mt-4">
                            <p className="font-semibold mr-2">
                              Desconto aplicado:
                              <span className="text-green-600 p-2 ml-2 rounded-md bg-green-100 round">{` ${promotionalCodeInfo?.discount_rate_formatted} OFF`}</span>
                            </p>
                          </div>
                        )}

                        <div className="flex mt-4">
                          <p className="font-semibold mr-2">Valor mensal: </p>
                          <p>
                            <span className="mr-1">R$</span>
                            {selectedPeriod
                              ? selectedPeriod.value_per_month_formatted
                              : 0}
                          </p>
                        </div>
                        <div className="flex">
                          <p className="font-semibold mr-2">
                            Número de parcelas
                          </p>
                          <p>{selectedPeriod.installments}</p>
                        </div>
                        <div className="flex">
                          <p className="font-semibold mr-2">Valor total: </p>
                          <p>
                            <span className="mr-1">R$</span>
                            {selectedPeriod
                              ? selectedPeriod.total_value_formatted
                              : 0}
                          </p>
                        </div>
                        <div className="my-5">
                          <div className="flex items-center">
                            <Checkbox
                              name="terms_of_service"
                              id="termsOfService"
                              // label="Li e concordo com os termos contidos no "
                              // Adicionar valor mensal
                              labelCustom={
                                <div className="flex items-center ml-2">
                                  <label htmlFor="termsOfService">
                                    Li e concordo com os termos contidos no
                                    <PDFTermsOfService
                                      cel={values.representative.phone}
                                      cep={values.company.cep}
                                      cnpj={values.company.cnpj}
                                      cpf={values.representative.cpf}
                                      email={values.representative.email}
                                      emailCompany={values.company.email}
                                      address={values.company.address}
                                      license={amountLicences.toString()}
                                      name={values.representative.name}
                                      companyName={values.company.name}
                                      valuePerMonth={
                                        selectedPeriod
                                          ? selectedPeriod.value_per_month_formatted
                                          : '0'
                                      }
                                      totalValue={
                                        selectedPeriod
                                          ? selectedPeriod.total_value_formatted
                                          : '0'
                                      }
                                      period={
                                        selectedPeriod
                                          ? selectedPeriod.period
                                          : ''
                                      }
                                      licenseAmountTotal={
                                        selectedPeriod.license_groups
                                      }
                                      saveBlob={(file) => setContractBlob(file)}
                                      discount_rate={
                                        promotionalCodeInfo?.discount_rate_formatted
                                      }
                                      installments={selectedPeriod.installments}
                                    />
                                  </label>
                                </div>
                              }
                              /* error={errors.terms_of_service}
                            touched={touched.terms_of_service} */
                              // onChange={(el) => console.log(el.target.checked)}
                            />
                          </div>
                          <div>
                            {!!errors.terms_of_service &&
                              touched.terms_of_service && (
                                <p className="text-[#E53E3E]">
                                  {errors.terms_of_service}
                                </p>
                                // eslint-disable-next-line prettier/prettier
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div
                  className={`flex justify-center lg:${step === 0 ? 'justify-end' : 'justify-between'}`}
                >
                  {step > 0 && (
                    <button
                      className={`bg-[#c2c4c2] w-[180px] h-[48px] rounded-md font- text-[20px]
                        hover:bg-[#7d7e7d]
                        ${isSubmitting ? 'bg-[#2F855A]' : ''} mr-4`}
                      type="button"
                      onClick={() => {
                        setStep(step - 1);
                      }}
                      disabled={isSubmitting}
                    >
                      <div className="flex justify-center items-center">
                        <FiArrowLeft />
                        <p className="mr-2">Voltar</p>
                      </div>
                    </button>
                  )}
                  {step === 0 && (
                    <button
                      className={`bg-[#48BB78] w-[180px] h-[48px] rounded-md font- text-[20px]
                        hover:bg-[#2F855A]
                         ${isSubmitting ? 'bg-[#2F855A]' : ''} `}
                      type="button"
                      disabled={isSubmitting}
                      onClick={async () => {
                        const validationErrors = await validateForm();
                        // console.log(validationErrors);
                        if (Object.keys(validationErrors).length > 0) {
                          setTouched(
                            setNestedObjectValues(validationErrors, true),
                          );
                          return;
                        }

                        setStep(step + 1);

                        /* Como não é trivial adicionar o loading nessa etapa,
                        apenas avançamos a etapa enquanto verificamos se o e-mail
                        é válido, para a tela não ficar "congelada" */
                        if (!verifiedEmail) {
                          /* Para previnir preenchimentos automáticos que não acionem
                          o evento onChange no input */
                          await handleVerifyEmail(
                            values.representative.email,
                            resetForm,
                          );

                          // Para nova validação
                          setVerifiedEmail(false);
                        }
                      }}
                    >
                      {isSubmitting ? (
                        <div className="flex justify-center">
                          <AiOutlineLoading className="animate-spin" />
                        </div>
                      ) : (
                        <div>Enviar</div>
                      )}
                    </button>
                  )}
                  {step === 1 && (
                    <button
                      className={`bg-[#48BB78] w-[180px] h-[48px] rounded-md font- text-[20px]
                        hover:bg-[#2F855A]
                         ${isSubmitting ? 'bg-[#2F855A]' : ''} `}
                      type="submit"
                      disabled={isSubmitting}
                      onClick={async () => {
                        const validationErrors = await validateForm();
                        // console.log(validationErrors);
                        if (Object.keys(validationErrors).length > 0) {
                          setTouched(
                            setNestedObjectValues(validationErrors, true),
                          );
                        }
                      }}
                    >
                      {isSubmitting ? (
                        <div className="flex justify-center">
                          <AiOutlineLoading className="animate-spin" />
                        </div>
                      ) : (
                        <div>Confirmar</div>
                      )}
                    </button>
                  )}
                </div>
              </Modal>
            </div>
          </Form>
        )}
      </Formik>
      <Formik
        initialValues={initialDataCode}
        onSubmit={(values, actions) => {
          // console.log(values);
          handleSubmitCode(values, actions);
        }}
        // validationSchema={schema}
      >
        {({ errors, touched, isSubmitting, resetForm }) => (
          <Form>
            <div className="flex flex-col lg:flex-row justify-center items-center mt-6">
              <Modal
                open={openCode}
                onClose={() => {
                  resetForm();
                  setOpen(false);
                  setOpenCode(false);
                  document.body.style.overflow = 'hidden';

                  // setStep(0);
                }}
                title="Confirmação de Email"
              >
                {step === 2 && (
                  <>
                    <div>
                      <div className="flex flex-col container-custom justify-around pt-[20px] overflow-auto max-h-full">
                        <div className="flex mb-4">
                          <p className="text-xl mr-2">
                            Confirme o código que enviamos em seu email
                          </p>
                          {/* <p className="text-xl font-bold">{buyer?.email}</p> */}
                          <p className="text-xl font-bold">
                            {representative?.email}
                            {/* {representative ? representative.email : ''} */}
                          </p>
                        </div>

                        <div>
                          <Input
                            name="code"
                            label="Código"
                            placeholder="Informe o código enviado"
                            error={errors.code}
                            touched={touched.code}
                          />
                        </div>
                        <div className="flex mt-2 items-center">
                          <p className="mr-2">Não encontrou?</p>
                          <button
                            type="button"
                            className="w-[135px] text-blue-500 border border-blue-500 hover:text-white hover:bg-blue-600 rounded-lg p-2 h-10 ml-2"
                            onClick={handleResendCode}
                            disabled={loadingResendCode}
                          >
                            {loadingResendCode ? (
                              <div className="flex justify-center">
                                <AiOutlineLoading className="animate-spin" />
                              </div>
                            ) : (
                              'Reenviar código'
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="flex justify-center lg:justify-end">
                      <button
                        className={`bg-[#48BB78] w-[180px] h-[48px] rounded-md font- text-[20px] hover:bg-[#2F855A] ${isSubmitting ? 'bg-[#2F855A]' : ''} `}
                        type="submit"
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? (
                          <div className="flex justify-center">
                            <AiOutlineLoading className="animate-spin" />
                          </div>
                        ) : (
                          'Enviar'
                        )}
                      </button>
                    </div>
                  </>
                )}
              </Modal>
            </div>
          </Form>
        )}
      </Formik>
      <Formik
        initialValues={initialDataCard}
        onSubmit={(values, actions) => {
          handleSubmitPayment(values, actions);
        }}
        validationSchema={schemaCreditCard}
      >
        {({ errors, touched, isSubmitting }) => (
          <Form>
            <Modal
              open={openPayment}
              onClose={() => {
                // resetForm();
                setOpen(false);
                setOpenCode(false);
                setOpenPayment(false);
                // document.body.style.overflow = 'hidden';

                setStep(0);
              }}
              title="Pagamento"
            >
              {step === 3 && (
                <>
                  <div className="flex flex-col items-center justify-around lg:flex-row p-4">
                    <div className="max-w-96 px-4">
                      <Input
                        toUpperCase
                        name="name"
                        label="Nome"
                        placeholder="Digite corretamente o nome escrito no cartão"
                        onChange={(el) => setCardName(el.target.value)}
                        onFocus={() => setFocus('name')}
                        error={errors.name}
                        touched={touched.name}
                        autoFocus
                        maxLength={16}
                      />
                      <Input
                        name="number"
                        label="Numero do cartão"
                        placeholder="Digite o numero do cartão corretamente"
                        onChange={(el) => setCardNumber(el.target.value)}
                        onFocus={() => setFocus('number')}
                        error={errors.number}
                        touched={touched.number}
                        trim
                      />
                      <div className="flex justify-center">
                        <Input
                          name="cvc"
                          label="CVC"
                          placeholder="Digite o código CVV do cartão "
                          onChange={(el) => setCardCVC(el.target.value)}
                          onFocus={() => setFocus('cvc')}
                          error={errors.cvc}
                          touched={touched.cvc}
                          maxLength={3}
                        />
                        <div className="ml-4">
                          <Input
                            name="expiry"
                            label="Vencimento"
                            placeholder="Digite a data de validade do seu cartao"
                            type="text"
                            mask="competence"
                            onChange={(el) => {
                              setCardExpiry(competenceMask(el.target.value));
                            }}
                            onFocus={() => setFocus('expiry')}
                            error={errors.expiry}
                            touched={touched.expiry}
                            // maxLength={4}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col items-center justify-around p-4">
                      <Cards
                        number={cardNumber}
                        expiry={cardExpiry}
                        cvc={cardCVC}
                        name={cardName}
                        placeholders={{ name: 'Seu Nome' }}
                        focused={focus}
                      />

                      <hr className="my-7 w-full" />
                      <div className="flex justify-between w-full mb-2">
                        <p className="text-gray-400">Quantidade de licenças</p>
                        <p>{`${collaboratorsAmount} un.`}</p>
                      </div>

                      <div className="flex justify-between w-full mb-2">
                        <p className="text-gray-400">Parcelas</p>
                        <p>{`${selectedPeriod.installments}x`}</p>
                      </div>

                      {!!promotionalCodeInfo && (
                        <div className="flex justify-between w-full mb-2">
                          <p className="text-gray-400">Desconto aplicado</p>
                          <p>{`${promotionalCodeInfo.discount_rate_formatted}`}</p>
                        </div>
                      )}

                      {/* <div className="flex justify-between w-full border-dashed border-t-2
                      border-[#cacaca] mt-7 pt-3">
                        <p className="text-lg">Valor mensal</p>
                        <p className="text-lg">
                          {`R$ ${selectedPeriod.value_per_month_formatted}`}
                        </p>
                      </div>
                      <div className="flex justify-between w-full border-[#cacaca]">
                        <p className="text-lg">Parcelas</p>
                        <p className="text-lg">
                          {`${selectedPeriod.installments}x`}
                        </p>
                      </div> */}
                      <div className="flex justify-between w-full border-dashed border-t-2 border-[#cacaca] mt-7 mb-2 pt-3">
                        <p className="text-lg font-bold">Valor Mensal</p>
                        <p className="text-lg font-bold">
                          {`R$ ${selectedPeriod.value_per_month_formatted}`}
                        </p>
                      </div>
                      <div className="flex justify-between w-full border-[#cacaca]">
                        <p className="text-xl font-bold">Total</p>
                        <p className="text-xl font-bold">
                          {`R$ ${selectedPeriod.total_value_formatted}`}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-center lg:justify-end">
                    <button
                      className="bg-[#48BB78] w-[180px] h-[48px] rounded-md font- text-[20px] hover:bg-[#2F855A]  "
                      type="submit"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? (
                        <div className="flex justify-center">
                          <AiOutlineLoading className="animate-spin" />
                        </div>
                      ) : (
                        'Enviar'
                      )}
                    </button>
                  </div>
                </>
              )}
            </Modal>
          </Form>
        )}
      </Formik>
    </section>
  );
};

export default Plans;
