import React, {
  ButtonHTMLAttributes,
  InputHTMLAttributes,
  useState,
} from 'react';

interface CardProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  title: string;
  price?: string;
  totalPrice?: string;
  totalPricePromoCode?: string;
  description?: string;
  name: string;
}

const Card: React.FC<CardProps> = ({
  title,
  price,
  totalPrice,
  totalPricePromoCode,
  description,
  onClick,
  name,
}) => (
  <div className="bg-gradient-to-t from-primary to-white w-[330px] h-[339px] rounded-[25px] flex flex-col justify-between  border-[#2c5282] border-[2px] m-5 pt-8 pb-4 lg:hover:scale-[1.2] transition-all shadow-md">
    <p className="flex justify-center  font-inter font-medium text-[32px]">
      {title}
    </p>

    <div>
      <div className="flex justify-center">
        {totalPricePromoCode ? (
          <div>
            <div className="flex justify-center flex-wrap">
              <p className="text-start mr-2">
                <span className="text-xl text-gray-600 mr-2">De</span>
                <span className="font-inter font-extrabold text-[22px] line-through">{`R$ ${totalPrice}`}</span>
              </p>
              <p className="text-start">
                <span className="text-xl text-gray-600 mr-2">por</span>
                <span className="font-inter font-extrabold text-[22px]">{`R$ ${totalPricePromoCode}`}</span>
              </p>
            </div>
            <p className="text-center text-xl text-gray-600 text-[22px]">
              Equivalente a
            </p>
          </div>
        ) : (
          <p className="">
            <span className="text-start font-inter font-extrabold text-[22px] mr-2">{`R$ ${totalPrice}`}</span>
            <span className="text-start text-xl text-gray-600 text-[22px]">
              Equivalente a
            </span>
          </p>
        )}
      </div>
      <div className="flex justify-center items-center">
        <p className="font-inter text-gray-600 text-[22px] mr-1">R$</p>
        <p className="font-inter font-extrabold text-[36px]">{price}</p>
        <p className="text-xl text-gray-600 text-[36px]">/Mês</p>
      </div>

      {description && (
        <div className="flex justify-center text-center mt-[48px] ">
          <p className=" pr-[30px] pl-[30px] font-montserrat text-[20px] leading-6">
            {description}
          </p>
        </div>
      )}
    </div>

    <div className="flex justify-center">
      <button
        className=" bg-[#2687E9] w-[195px] h-[50px] rounded-[50px] shadow-md hover:bg-[#2B6CB0]"
        type="button"
        onClick={onClick}
      >
        <input type="radio" className="hidden" name={name} />
        <p className="text-white font-inter font-bold text-[24px]">Contrate</p>
      </button>
    </div>
  </div>
);

export default Card;
