import React, { InputHTMLAttributes } from 'react';

import { Field, FieldInputProps, FormikProps } from 'formik';

interface DataObj {
  [key: string]: string;
}

interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  id?: string;
  label?: string;
  error?: string;
  touched?: boolean;
  labelCustom?: React.ReactNode;
}

const Checkbox: React.FC<CheckboxProps> = ({
  name,
  id,
  label,
  onChange,
  error,
  touched,
  labelCustom,
  ...rest
}) => (
  <div className="flex ">
    <Field>
      {({
        field,
      }: {
        field: FieldInputProps<string>;
        form: FormikProps<DataObj>;
      }) => (
        <div className={labelCustom ? 'flex items-center' : ''}>
          <input
            {...field}
            {...rest}
            type="checkbox"
            id={id}
            name={name}
            onChange={(ev) => {
              field.onChange(ev);
              if (onChange) {
                onChange(ev);
              }
            }}
          />
          {!labelCustom ? (
            <label htmlFor={`#${id}`} className="ml-2">
              {label}
            </label>
          ) : (
            <div>{labelCustom}</div>
          )}

          {!!error && touched && <p className="text-[#E53E3E]">{error}</p>}
        </div>
      )}
    </Field>
  </div>
);

export default Checkbox;
