import React, { useEffect, useMemo, useState } from 'react';
import { FaRegCircleCheck, FaRegCircleXmark } from 'react-icons/fa6';
import { FiX } from 'react-icons/fi';

interface ToastProps {
  title: string;
  status: 'success' | 'error' | 'warning';
  onClose(): void;
}

const Toast: React.FC<ToastProps> = ({ status, title, onClose }) => {
  const colorScheme = useMemo((): string => {
    switch (status) {
      case 'success':
        return 'border-green-200 bg-green-200 text-green-700 shadow-lg shadow-green-400/10';

      case 'error':
        return 'border-red-200 bg-red-200 text-red-700 shadow-lg shadow-red-400/10';

      case 'warning':
        return 'border-yellow-200 bg-yellow-200 text-yellow-700 shadow-lg shadow-yellow-400/10';

      default:
        return 'blue';
    }
  }, [status]);

  const colorSchemeButton = useMemo((): string => {
    switch (status) {
      case 'success':
        return 'border-green-300 hover:bg-green-200';

      case 'error':
        return 'border-red-300 hover:bg-red-200';

      case 'warning':
        return 'border-yellow-300 hover:bg-yellow-200';

      default:
        return 'blue';
    }
  }, [status]);

  return (
    <div
      className={`fixed top-8 right-8 z-[2000] max-w-lg rounded-lg px-4 py-3 border ${colorScheme}`}
    >
      <div className="flex items-center gap-2">
        <p className="font-inter font-medium">{title}</p>
        <div className="ml-3">
          <button
            className={`rounded-lg p-1 border ${colorSchemeButton}`}
            aria-label="close"
            type="button"
            onClick={onClose}
          >
            <FiX />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Toast;
