import React from 'react';
import { FiCheckSquare } from 'react-icons/fi';

import logoPlan from 'assets/logo-plans.png';
import Card from 'components/Card';

const Functionalities: React.FC = () => (
  <section className="container-custom mt-20 lg:mt-40">
    <div>
      <div className="w-full flex justify-center relative">
        <div className="flex flex-col items-center">
          <div className="flex flex-col items-center md:items-end">
            <div className="w-[450px] h-24 md:w-[780px] md:h-[114px] lg:w-[1221px] xl:w-[1321px] bg-[#2c5282] lg:rounded-l-full z-10" />
            <div className="w-[450px] h-24 md:w-[780px] md:h-[114px] lg:w-[1068px] xl:w-[1168px] bg-[#2c5282] lg:rounded-l-full mt-[-46px] z-10" />
          </div>
          <div className="flex justify-center w-full">
            <p className="text-center sm:text-start font-monoton absolute text-[26px] md:text-[50px] lg:text-[64px] text-white z-10 top-12">
              Funcionalidades GDP
            </p>
          </div>
        </div>
      </div>

      <div className="flex flex-col items-center">
        <div className="xl:w-[1321px] xl:h-[835px] lg:rounded-tl-[300px] lg:rounded-br-[300px] bg-[#a3d8cf] flex flex-col justify-center border-[2px] border-black mt-[-105px]">
          <div className="sm:px-8 md:px-16 pt-36 py-10 xl:px-[80px] xl:pt-[100px] px-5">
            <div className="flex items-center">
              <div className="w-[30px] mr-[10px]">
                <FiCheckSquare size={30} />
              </div>

              <p className="font-inter font-medium text-base md:text-[28px]">
                Definição de Times.
              </p>
            </div>
            <br />
            <div className="flex items-center">
              <div className="w-[30px] mr-[10px]">
                <FiCheckSquare size={30} />
              </div>
              <p className="font-inter font-medium text-base md:text-[28px]">
                Definição da sua estrutura Hierarquica de cargos.
              </p>
            </div>
            <br />
            <div className="flex items-center">
              <div className="w-[30px] mr-[10px]">
                <FiCheckSquare size={30} />
              </div>
              <p className="font-inter font-medium text-base md:text-[28px]">
                Desdobramento de itens a serem avaliados para cada cargo.
              </p>
            </div>
            <br />
            <div className="flex items-center">
              <div className="w-[30px] mr-[10px]">
                <FiCheckSquare size={30} />
              </div>
              <p className="font-inter font-medium text-base md:text-[28px]">
                Definição de ciclos avaliativos.
              </p>
            </div>
            <br />
            <div className="flex items-start">
              <div className="w-[30px] mr-[10px]">
                <FiCheckSquare size={30} />
              </div>

              <p className="font-inter font-medium text-base md:text-[28px] leading-8">
                Determinação de pontuação para cada item da avaliação e cada
                colaborador por periodo avaliativo.
              </p>
            </div>
            <br />
            <div className="flex items-start">
              <div className="w-[30px] mr-[10px]">
                <FiCheckSquare size={30} />
              </div>

              <p className="font-inter font-medium text-base md:text-[28px] leading-10">
                Cada colaborador com seu painel de acompanhamento das
                acompanhamento das atividades e feedback.
              </p>
            </div>
            <br />
            <div className="flex items-center">
              <div className="w-[30px] mr-[10px]">
                <FiCheckSquare size={30} />
              </div>
              <p className="font-inter font-medium text-base md:text-[28px]">
                Avaliação TOP DOWN, 180°, 360°
              </p>
            </div>
            <br />
            <div className="flex items-start">
              <div className="w-[30px] mr-[10px]">
                <FiCheckSquare size={30} />
              </div>
              <p className="font-inter font-medium text-base md:text-[28px] md:pr-20 leading-8">
                Possibilidade de registros de feedback de advertencia/melhorias
                e da superação (reconhecimento).
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default Functionalities;
