import React, { useState } from 'react';

import avaliacao from 'assets/avaliacao.png';
import avaliacao2 from 'assets/avaliacao2.png';
import avaliacao3 from 'assets/avaliacao3.png';
import vectorSquare from 'assets/vector-square.png';
import vector from 'assets/vector-triangle-custom-cut.png';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/pagination';
// import 'swiper/css/navigation'; setas laterais

const Hero: React.FC = () => {
  /* const [toggle, setToggle] = useState(false); */
  const slides = [avaliacao2, avaliacao, avaliacao3];
  return (
    <div className="relative  pt-[48px] bg-[#6FA8DD] sm:bg-inherit md:min-h-screen h-[885px]">
      <img
        src={vector}
        alt=""
        className="absolute w-full h-full top-0 left-0 hidden sm:block"
      />
      <section className="container mx-auto">
        <div className="relative h-[200px]">
          <p className="text-center sm:text-start text-black text-4xl leading-[50px] lg:text-6xl lg:leading-[84px]">
            Gestão de Desempenho Profissional - GDP
          </p>
          <img
            src={vectorSquare}
            alt="vector"
            className="absolute top-1 left-[-70px]  w-28 xl:w-auto xl:left-[-114px]"
          />

          <div className="flex flex-col items-center lg:justify-between mt-40 lg:flex-row">
            <p
              className="text-center lg:text-start mb-6 text-3xl z-10 leading-0 xl:text-5xl xl:leading-[48px] lx:mb-0 w-auto h-auto lg:mr-6"
              style={{ textShadow: '2px 2px 1px white' }}
            >
              Sua solução para avaliação e acompanhamento dos desempenhos do
              seus colaboradores
            </p>

            <div className="rounded-3xl border border-black max-w-[320px] md:max-w-[700px] mt-7 lg:mt-0 overflow-hidden">
              <Swiper
                width={700}
                // spaceBetween={30}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                modules={[Navigation, Pagination, Autoplay]}
                navigation
                loop
                pagination={{
                  clickable: true,
                }}
              >
                {slides.map((slide, key) => (
                  <SwiperSlide key={`${key + 1}`}>
                    <img
                      src={slide}
                      alt="Imagens do sistema GDP"
                      className="object-cover h-52 w-80 md:h-96 md:w-[700px] object-top"
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default Hero;
