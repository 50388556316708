import React, { InputHTMLAttributes } from 'react';

import { Field, FieldInputProps, FormikProps } from 'formik';
import {
  cepMask,
  cnpjMask,
  competenceMask,
  cpfMask,
  onlyNumbersMask,
  phoneMask,
} from 'utils/mask';

interface DataObj {
  [key: string]: string;
}

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  id?: string;
  placeholder?: string;
  label?: string;
  error?: string;
  touched?: boolean;
  type?: string;
  mask?: 'none' | 'cnpj' | 'cpf' | 'phone' | 'cep' | 'competence';
  toUpperCase?: boolean;
  validate?(value: string | number): string;
  onBlurCustom?(): void;
  width?: string;
  margin?: string;
  background?: string;
  trim?: boolean;
}

const Input: React.FC<InputProps> = ({
  label,
  name,
  id,
  placeholder,
  type,
  mask,
  validate,
  onBlurCustom,
  error,
  touched,
  width = 'full',
  margin = 'mb-4 lg:mb-8',
  background = '[#EAF5FD]',
  onChange,
  onFocus,
  toUpperCase,
  trim,
  // children,
  ...rest
}) => {
  function handleCustomMask(form: FormikProps<DataObj>, value: string): void {
    // console.log(value);
    switch (mask) {
      case 'cnpj':
        form.setFieldValue(name, cnpjMask(value));

        break;
      case 'cpf':
        form.setFieldValue(name, cpfMask(value));
        break;
      case 'phone':
        form.setFieldValue(name, phoneMask(value));
        break;
      case 'cep':
        form.setFieldValue(name, cepMask(value));
        break;

      case 'competence':
        form.setFieldValue(name, competenceMask(value));
        break;

      default:
    }
  }

  return (
    <div className={margin}>
      <Field name={name}>
        {({
          field,
          form,
        }: {
          field: FieldInputProps<string>;
          form: FormikProps<DataObj>;
        }) => (
          <>
            <label htmlFor={id} className="font-montserrat">
              {/* 9ca3af */}
              {label}
            </label>
            <div>
              <input
                {...field}
                {...rest}
                className={`bg-${background} border-0 w-${width} h-[40px] p-[15px] rounded-lg ${!!error && touched ? 'border-[1px] border-[#E53E3E] block focus:shadow-[#FC8181] focus:shadow-sm' : ''} focus:shadow-md focus-visible:outline-none focus:shadow-[#9dbdf4]`}
                type={type ?? 'text'}
                name={name}
                placeholder={placeholder}
                id={id}
                onChange={(ev) => {
                  field.onChange(ev);
                  if (mask) {
                    handleCustomMask(form, ev.target.value);
                  }

                  if (trim) {
                    form.setFieldValue(name, onlyNumbersMask(ev.target.value));
                  }

                  if (toUpperCase) {
                    form.setFieldValue(name, ev.target.value.toUpperCase());
                  }

                  if (onChange) {
                    onChange(ev);
                  }
                }}
                /*  value={field.value} */
                onBlur={(ev) => {
                  // console.log(field, form.errors);
                  field.onBlur(ev);
                  if (onBlurCustom) onBlurCustom();
                }}
                onFocus={(ev) => onFocus && onFocus(ev)}
              />
              {!!error && touched && <p className="text-[#E53E3E]">{error}</p>}
            </div>
          </>
        )}
      </Field>
    </div>
  );
};

export default Input;
