import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_API,
});

const apiMyIpac = axios.create({
  baseURL: process.env.REACT_APP_API_LARAVEL,
  headers: {
    'X-Token-Api': process.env.REACT_APP_TOKEN_MYIPAC_API,
    company: 1,
  },
});

const apiBarte = axios.create({
  baseURL: `https://cors-anywhere.herokuapp.com/corsdemo/${process.env.REACT_APP_BARTE_API}`,
  headers: {
    'X-Token-Api': process.env.REACT_APP_BARTE_TOKEN,
  },
});

export { api, apiMyIpac, apiBarte };
