import React from 'react';

function formatMoney(number: number | string, decimalValues?: number) {
  return Number(number)
    .toFixed(decimalValues || 2)
    .replace('.', ',');
}

function formatPercentage(number: number | string) {
  return `${Number(number) * 100}%`;
}

export { formatMoney, formatPercentage };
