import React, { useState } from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { FiArrowDownCircle } from 'react-icons/fi';

interface AccordionProps {
  question: string;
  children: React.ReactNode;
}

const AccordionQuest: React.FC<AccordionProps> = ({ question, children }) => {
  const [toggleResponse, setToggleResponse] = useState(false);
  return (
    <div
      className="flex flex-col bg-[#EDF2F7] border-black border-[2px] m-[15px] rounded-md"
      onClick={() => setToggleResponse(!toggleResponse)}
      role="button"
      tabIndex={0}
    >
      <div className="flex justify-between items-center">
        <p className="font-inter px-10 flex items-center text-2xl sm:text-[32px] text-[#375F88] pt-[12px] pb-[12px]">
          {question}
        </p>
        <div className="pr-8 top-4 duration-200">
          {!toggleResponse ? (
            <FaChevronDown size={24} />
          ) : (
            <FaChevronUp size={24} />
          )}
        </div>
      </div>
      {toggleResponse && (
        <div className="flex flex-col font-anonymousPro p-[20px] sm:leading-8 text-base sm:text-[28px]">
          {children}
        </div>
      )}
    </div>
  );
};

export default AccordionQuest;
