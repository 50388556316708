import React from 'react';

import interrogationCut from 'assets/interrogacao-cut.png';
import interrogationOpacity from 'assets/interrogacao-opacity.png';
import interrogation from 'assets/interrogacao.png';
import vectorX from 'assets/vector-X.png';
import Functionalities from 'sections/Functionalities';
import Hero from 'sections/Hero';
import Plans from 'sections/Plans';
import Questions from 'sections/Questions';

const App: React.FC = () => (
  <div className="overflow-hidden">
    {/* <Toast status="sucess" title="Formulário enviado com sucesso!" /> */}

    {/* <header className="flex justify-between items-center h-[75px] border-b-2 border-[#808080]">
      <p className="text-2xl ml-[138px]">
        <img src={logo} alt="logo IPAC" className="w-[60px]" />
      </p>

      <p className="text-2xl">Planos</p>

      <p className="text-2xl">Funcionalidades</p>

      <p className="text-2xl">Fale Conosco</p>

      <p className="text-2xl mr-[132px]">Entrar</p>
    </header> */}
    <main className="relative overflow-hidden z-10">
      <Hero />
      <section className="bg-gradient-to-b from-[#6FA8DD] container-custom">
        <Plans />

        <img
          src={vectorX}
          alt="vectores"
          className="hidden sm:block w-96 absolute top-[640px] right-[-220px] lg:w-[590px]"
        />
      </section>

      <section className="bg-gradient-to-t  from-primary to-[white] container-custom relative">
        <Functionalities />

        <Questions />
        <img
          src={interrogation}
          alt="imagem de interrogação"
          className="absolute bottom-28 left-0 hidden md:block"
        />
        <img
          src={interrogationCut}
          alt="imagem de interrogação"
          className="absolute bottom-[832px] right-0 hidden md:block"
        />
        <img
          src={interrogationOpacity}
          alt="imagem de interrogação"
          className="absolute bottom-44 right-0 hidden md:block"
        />
      </section>
    </main>
  </div>
);

export default App;
