import React, { SelectHTMLAttributes, useState } from 'react';

import userEvent from '@testing-library/user-event';
import { Field, FieldInputProps, FormikProps } from 'formik';
import { Interface } from 'readline';

export interface Option {
  value: number | string;
  label: number | string;
}

interface SelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  name: string;
  id?: string;
  placeholder?: string;
  label?: string;
  type?: string;
  validate?: string;
  options: Option[];
  error?: string;
  touched?: boolean;
}

interface DataObj {
  [key: string]: string;
}

const Select: React.FC<SelectProps> = ({
  label,
  name,
  options,
  id,
  placeholder,
  error,
  touched,
}) => (
  <div className="mb-4 lg:mb-8">
    <Field name={name}>
      {({
        field,
        // form,
      }: {
        field: FieldInputProps<string>;
        form: FormikProps<DataObj>;
      }) => (
        <>
          <label htmlFor={id} className="font-montserrat">
            {label}
          </label>
          <div>
            <select
              {...field}
              name={name}
              className={`bg-[#EAF5FD]  border-0 w-full lg:w-[450px] h-[40px] pl-[15px] rounded-lg ${!!error && touched ? 'border-[1px] border-[#E53E3E] focus:shadow-[#FC8181] focus:shadow-sm' : ''}  focus:shadow-md focus-visible:outline-none focus:shadow-[#9dbdf4]`}
              id={id}
            >
              <option value="" disabled className="text-[#9ca3af]">
                {placeholder}
              </option>

              {options?.map((item) => (
                <option key={`${item.value}-${item.label}`} value={item.value}>
                  {item.label}
                </option>
              ))}
            </select>

            {!!error && touched && <p className="text-[#E53E3E]">{error}</p>}
          </div>
        </>
      )}
    </Field>
  </div>
);

export default Select;
