import React, { useEffect, useCallback } from 'react';
import { FiX } from 'react-icons/fi';

import logo from 'assets/logo-2.png';

interface ModalProps {
  open: boolean;
  onClose(): void;
  children?: React.ReactNode;
  title: string;
}

const Modal: React.FC<ModalProps> = ({ open, onClose, children, title }) => {
  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden';
    }
  }, [open]);

  const handleClose = useCallback(() => {
    onClose();

    document.body.style.overflow = 'auto';
  }, [onClose]);

  return (
    <div
      /* onClick={onClose} */
      className={`inset-0 top-0 fixed flex justify-center items-center transition-colors ${open ? 'visible bg-black/50' : 'invisible'} z-[1000]  `}
    >
      {/* Modal */}
      <div
        // onClick={(e) => e.stopPropagation()}
        className={`bg-[#f0f8ff] rounded-xl w-[1310px] p-6 transition-all ${open ? 'scale-100 opacity-100' : 'scale-125 opacity-0'} z-[1000] relative overflow-y-auto`}
        style={{ maxHeight: 'calc(100% - 7.5rem)' }}
        aria-hidden="true"
      >
        <div>
          <button
            className="absolute top-2 right-2  text-gray-400 bg-white hover:bg-gray-50 hover:text-gray-600 w-[40px] h-[40px] flex items-center justify-center rounded-[20px] shadow-md"
            onClick={handleClose}
            type="button"
            aria-label="close"
          >
            <FiX />
          </button>
        </div>
        <div className="">
          <div className="mx-auto my-4 flex text-center w-full items-center">
            <img
              alt="logo IPAC"
              src={logo}
              className="w-[45px] h-[45px] md:w-[70px] md:h-[70px]"
            />

            <p className="text-black text-[24px] md:text-[48px] font-inter mr-auto ml-auto">
              {title}
            </p>
          </div>
          {children}
        </div>
      </div>
    </div>
  );
};

export default Modal;
