/* eslint-disable no-tabs */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FiExternalLink } from 'react-icons/fi';

import imagotype from 'assets/logo-name.png';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { TDocumentDefinitions, TableCell } from 'pdfmake/interfaces';
import { LicenseGroups } from 'sections/Plans';

interface TermsOfServiceProps {
  companyName: string;
  cnpj: string;
  address: string;
  cep: string;
  emailCompany: string;
  name: string;
  cpf: string;
  cel: string;
  email: string;
  license: string;
  valuePerMonth: string;
  totalValue: string;
  discount_rate?: string | null;
  period: string;
  licenseAmountTotal: LicenseGroups[];
  installments: number;
  saveBlob?(file: Blob): void;
}

const PDFTermsOfService: React.FC<TermsOfServiceProps> = ({
  companyName,
  cnpj,
  address,
  cep,
  emailCompany,
  name,
  cpf,
  cel,
  email,
  license,
  valuePerMonth,
  totalValue,
  period,
  licenseAmountTotal,
  saveBlob,
  discount_rate,
  installments,
  /* No momento utilizando apenas o nome do corretor,
  verificar se é necessário incluir o cnpj dele ou do condomínio, como está */
}) => {
  // verificar quando da bloqueio de popup no navegador, ocorre apenas por conta do _blank
  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  /* pdfMake.fonts = {
    Arial: {
      normal: 'Arial.ttf',
    },
  }; */
  const pdfDoneRef = useRef(false);
  const [pdfFile, setPdfFile] = useState<pdfMake.TCreatedPdf>();

  const currentDate = new Date();
  const formatDate = format(currentDate, "dd 'de' LLLL 'de' yyyy", {
    locale: ptBR,
  });

  const installmentsExtense = useMemo(() => {
    switch (installments) {
      case 12:
        return '12 (doze)';
      case 6:
        return '6 (seis)';
      case 3:
        return '3 (três)';
      default:
        return '';
    }
  }, [installments]);

  useEffect(() => {
    /* async function convertImgToBase64() {
      const imagotypeBase64 = await fs.promises.readFile(imagotype, {
        encoding: 'base64',
      });

      console.log(imagotypeBase64);

      return imagotypeBase64;
    } */
    // console.log(licenseAmountTotal, saveBlob);
    const convertImageToBase64 = () => {
      if (pdfDoneRef.current) {
        return;
      }

      pdfDoneRef.current = false;

      let ImagotypeBase64 = '';

      fetch(imagotype)
        .then((response) => response.blob())
        .then((blob) => {
          // console.log(blob, totalValue);
          const reader = new FileReader();
          reader.onloadend = () => {
            ImagotypeBase64 = reader.result as string;

            const document: TDocumentDefinitions = {
              info: { title: 'Contrato - MyIPAC|GDP' },
              pageSize: 'A4',
              pageMargins: [40, 53, 40, 48],
              header: {
                margin: [40, 12, 40, 0],
                layout: 'noBorders',
                table: {
                  widths: [100, '*'],
                  body: [
                    [
                      {
                        image: ImagotypeBase64,
                        fit: [100, 100],
                      },
                      {
                        text: 'MYIPAC - GDP',
                        style: 'reportName',
                        alignment: 'right',
                      },
                    ],
                  ],
                },
              },
              content: [
                /* {
                  ol: [
                    {text: 'lala', listType: }
                  ]
                } */
                {
                  text: 'PROPOSTA TÉCNICA COMERCIAL - MYIPAC',
                  alignment: 'center',
                  bold: true,
                },
                {
                  text: '<folha de rosto>',
                  alignment: 'center',
                  bold: true,
                },
                {
                  layout: 'noBorders',
                  table: {
                    widths: '*',
                    body: [[{ text: formatDate }]],
                  },
                },
                {
                  layout: 'noBorders',
                  table: {
                    widths: '*',
                    body: [[{ text: 'À' }]],
                  },
                },
                {
                  layout: 'noBorders',
                  table: {
                    widths: '*',
                    body: [[{ text: companyName }]],
                  },
                  marginBottom: 10,
                },
                {
                  text: 'É com grande satisfação que apresentamos a Proposta Técnica e Comercial do MyIPAC – módulo GDP, uma plataforma em nuvem baseada em tecnologia 4.0 e rigoroso padrão de segurança que tem a missão de apoiar a gestão empresarial na estruturação, implementação, avaliação e acompanhamento do desempenho da equipe de trabalho da sua empresa',
                  margin: [0, 0, 0, 4],
                },
                {
                  layout: 'noBorders',
                  table: {
                    body: [
                      [
                        {
                          text: 'Muito bem-vindo a nova forma de gestão de desempenho profissional',
                        },
                      ],
                    ],
                  },
                  margin: [0, 8, 0, 8],
                },
                {
                  text: 'DADOS DO CLIENTE',
                  decoration: 'underline',
                  alignment: 'center',
                  marginTop: 10,
                  marginBottom: 10,
                },
                {
                  layout: 'noBorders',
                  table: {
                    headerRows: 1,
                    widths: [70, 'auto', 'auto', 70],
                    body: [
                      ['', 'Razão Social: ', companyName, ''],
                      ['', 'CNPJ Nº: ', cnpj, ''],
                      ['', 'Endereço: ', address, ''],
                      ['', 'CEP: ', cep, ''],
                      ['', 'E-mail corporativo: ', emailCompany, ''],
                      ['', 'Representante: ', name, ''],
                      ['', 'CPF n°: ', cpf, ''],
                      ['', 'Cel: ', cel, ''],
                      ['', 'E-mail: ', email, ''],
                    ],
                  },
                },
                {
                  text: 'ORÇAMENTO - PRODUTO / SERVICOS / LICENÇAS',
                  decoration: 'underline',
                  alignment: 'center',
                  marginTop: 20,
                  marginBottom: 10,
                },
                {
                  layout: 'noBorders',
                  table: {
                    // headerRows: 1,
                    dontBreakRows: true,
                    widths: [70, 150, 'auto', 70],
                    body: [
                      ['', 'Modalidade de Fidelização:', period, ''],
                      ['', 'Descrição:', 'MyIPAC – módulo GDP', ''],
                      ['', 'CEP: ', cep, ''],
                      ['', 'Email:', emailCompany, ''],
                      ['', 'Representante:', name, ''],
                      ['', 'Quantidade de licenças:', license, ''],
                      ['', 'Desconto aplicado:', discount_rate || '-', ''],
                      [
                        '',
                        'Valor:',
                        {
                          marginBottom: 10,
                          table: {
                            widths: '*',
                            body: [
                              ...licenseAmountTotal.map(
                                (linc) => [
                                  {
                                    text: `${linc.amount} licenças de R$
                                    ${linc.unit_value} = R$ ${linc.total}`,
                                  },
                                ], // verificar maneira correta de passar o array
                              ),
                            ],
                          },
                        },
                        '',
                      ],
                      ['', 'Valor mensal:', `R$ ${valuePerMonth}`, ''],
                      ['', 'Número de parcelas:', installments, ''],
                      ['', 'Valor total:', `R$ ${totalValue}`, ''],
                    ],
                  },
                },
                {
                  marginTop: 50,
                  text: [
                    'O ',
                    {
                      text: 'IPAC PREMIUM LTDA',
                      bold: true,
                    },
                    {
                      text: ', CNPJ nº. 03.603.228/0001-64, sito à Quadra C 01 Lote 01/12 - Salas 635 a 642 - Taguatinga - Brasília - DF, representante comercial do software Plataforma MyIPAC, doravante denominado ',
                    },
                    {
                      text: 'LICENCIANTE IPAC',
                      bold: true,
                    },
                    {
                      text: `, e a empresa “${companyName}”, inscrita no CNPJ Nº. ${cnpj}, situada no ${address}, CEP: ${cep}, aqui representada por ${name}, portador do CPF nº ${cpf}, Cel.: ${cel}, email.: ${emailCompany}, doravante denominado de `,
                      bold: true,
                    },
                    {
                      text: 'LICENCIADO',
                      bold: true,
                    },
                    {
                      text: ', têm entre si o presente Contrato o licenciamento de uso do software e outras avenças, ficando ajustado o seguinte;',
                    },
                  ],
                },
                {
                  text: 'CONDIÇÕES GERAIS PARA LICENCIAMENTO DE USO DO SOFTWARE MYIPAC',
                  marginTop: 50,
                  bold: true,
                },
                {
                  text: [
                    'Firmam as ',
                    {
                      text: 'PARTES',
                      bold: true,
                    },
                    ' descritas na ',
                    {
                      text: 'FOLHA DE ROSTO',
                      bold: true,
                    },
                    ', que faz parte integrante a essas condições gerais, o presente Contrato para o licenciamento de uso do software e outras avenças, doravante denominado de ',
                    {
                      text: 'PLATAFORMA MYIPAC',
                      bold: true,
                    },
                    {
                      text: `, sendo o licenciado a empresa ${companyName}, inscrita no CNPJ Nº. ${cnpj}, situada em ${address}, ${cep}, CEP: 00.000-000, aqui representada por ${name} na, portador do CPF nº ${cpf}, Cel.: ${cel}, email.: ${emailCompany} doravante denominado de `,
                    },
                    {
                      text: 'LICENCIADO',
                      bold: true,
                    },
                    ', e a empresa titular do domínio da ',
                    {
                      text: 'PLATAFORMA MYIPAC ',
                      bold: true,
                    },
                    'ora licenciado, doravante denominada de ',
                    {
                      text: 'LICENCIANTE IPAC',
                      bold: true,
                    },
                  ],
                },
                {
                  text: [
                    'Ao aceitar eletronicamente este Contrato, o ',
                    {
                      text: 'LICENCIADO',
                      bold: true,
                    },
                    ' estará automaticamente aderindo e ciente dos termos e condições deste Contrato e da Política de Privacidade e Proteção de dados pessoais do LICENCIANTEIPAC, cuja Política de Privacidade e Proteção de dados pessoais está disponível em https://www.qyon.com/lgpd, e é integrante destas condições gerais, na medida em que dispõe sobre a coleta, utilização, armazenamento, tratamento, compartilhamento, proteção e eliminação de dados pessoais eventualmente tratados por ocasião do vínculo entre a ',
                    {
                      text: 'LICENCIANTE IPAC',
                      bold: true,
                    },
                    ' e o ',
                    {
                      text: 'LICENCIADO',
                      bold: true,
                    },
                    ', em decorrência da utilização da ',
                    {
                      text: 'PLATAFORMA MYIPAC',
                      bold: true,
                    },
                  ],
                },
                {
                  text: '1.	DO OBJETO',
                  marginTop: 45,
                  bold: true,
                },
                {
                  marginLeft: 23,
                  text: [
                    '1.1.	Pelo presente Contrato, a ',
                    {
                      text: 'LICENCIANTE IPAC',
                      bold: true,
                    },
                    ' concede licença de uso da PLATAFORMA MYIPAC para utilização em equipamento do ',
                    {
                      text: 'LICENCIADO',
                      bold: true,
                    },
                    '.',
                  ],
                },
                {
                  marginLeft: 55,
                  text: [
                    '1.1.1.	A presente licença autoriza o ',
                    {
                      text: 'LICENCIADO',
                      bold: true,
                    },
                    ' a acessar e utilizar a ',
                    {
                      text: 'PLATAFORMA MYIPAC',
                      bold: true,
                    },
                    ' diretamente da nuvem, por meio de login fornecido pela ',
                    {
                      text: 'LICENCIANTE IPAC',
                      bold: true,
                    },
                    ' e senha a ser criada pelo ',
                    {
                      text: 'LICENCIADO',
                      bold: true,
                    },
                  ],
                },
                {
                  marginLeft: 55,
                  text: [
                    '1.2.	Além da licença de uso da ',
                    {
                      text: 'PLATAFORMA MYIPAC',
                      bold: true,
                    },
                    ' ao ',
                    {
                      text: 'LICENCIADO',
                      bold: true,
                    },
                    ', é objeto da presente condição geral a prestação de serviços de suporte técnico, atualização e manutenção relativamente a ',
                    {
                      text: 'PLATAFORMA MYIPAC',
                      bold: true,
                    },
                    ', via canal de comunicação “fale conosco” na plataforma e através do email suporte@myipac.com.br.',
                  ],
                },
                {
                  text: '2.	II – DO ACESSO A PLATAFORMA MYIPAC ',
                  marginTop: 35,
                  bold: true,
                },
                {
                  marginLeft: 23,
                  text: [
                    '2.1.	O acesso a ',
                    {
                      text: 'PLATAFORMA MYIPAC',
                      bold: true,
                    },
                    ' será através da internet, de acordo com os produtos contratados descritos na ',
                    {
                      text: 'FOLHA DE ROSTO.',
                      bold: true,
                    },
                  ],
                },
                {
                  marginLeft: 23,
                  text: [
                    '2.2.	Ao conceder acesso a ',
                    {
                      text: 'PLATAFORMA MYIPAC',
                      bold: true,
                    },
                    ', ou a qualquer funcionalidade nele disponível, a ',
                    {
                      text: 'LICENCIANTE IPAC',
                      bold: true,
                    },
                    ' não transfere ao ',
                    {
                      text: 'LICENCIADO',
                      bold: true,
                    },
                    ' quaisquer direitos de propriedade industrial e/ou intelectual sobre qualquer componente, criação, programa de computador, imagem, desenho, ou qualquer outro item disponível na ',
                    {
                      text: 'PLATAFORMA MYIPAC',
                      bold: true,
                    },
                    ', concedendo apenas e tão somente uma licença de uso temporária, não exclusiva, intransferível e revogável a qualquer tempo, a critério da ',
                    {
                      text: 'LICENCIANTE IPAC.',
                      bold: true,
                    },
                  ],
                },
                {
                  marginLeft: 23,
                  text: [
                    '2.3.	À ',
                    {
                      text: 'LICENCIANTE IPAC.',
                      bold: true,
                    },
                    ' fica reservado o direito de, a qualquer momento e a seu exclusivo critério, modificar as condições de acesso a ',
                    {
                      text: 'PLATAFORMA MYIPAC',
                      bold: true,
                    },
                    ', alterando seu design e/ou sua forma de apresentação, incluindo ou suprimindo funcionalidades e/ou informações, ou realizando qualquer outra alteração, de qualquer ordem, sem que tal fato resulte em qualquer direito de o ',
                    {
                      text: 'LICENCIADO',
                      bold: true,
                    },
                    ' reclamar perdas e danos, a qualquer título.',
                  ],
                },
                {
                  marginLeft: 23,
                  text: [
                    '2.4.	Todos os direitos de propriedade intelectual no tocante a ',
                    {
                      text: 'PLATAFORMA MYIPAC',
                    },
                    ' na forma desta proposta são de exclusiva propriedade da ',
                    {
                      text: 'LICENCIANTE IPAC',
                    },
                    ' sob a proteção e na forma da Lei 610/1998, incluindo, sem limitação, quaisquer aprimoramentos, correções, traduções, alterações, novas versões, obras derivadas ou customizadas, sendo que em nenhuma hipótese tal propriedade será transferida para ',
                    {
                      text: 'LICENCIADO',
                    },
                    ' ou para terceiros.',
                  ],
                },
                {
                  marginLeft: 55,
                  text: [
                    '2.4.1.	Na hipótese de a ',
                    {
                      text: 'LICENCIANTE IPAC',
                    },
                    ' concordar com a cessão da propriedade intelectual para o ',
                    {
                      text: 'LICENCIADO',
                    },
                    ' ou terceiros, a cessão deverá ser estruturada em documento específico, assinado pelos representantes legais com firmas reconhecidas em cartório.',
                  ],
                },
                {
                  marginLeft: 23,
                  text: [
                    '2.5.	Caso o ',
                    {
                      text: 'LICENCIADO',
                      bold: true,
                    },
                    ' crie contas de usuários para acesso a ',
                    {
                      text: 'PLATAFORMA MYIPAC',
                      bold: true,
                    },
                    ', deverá fornecer dados verdadeiros e precisos, ficando responsável pelo sigilo de seu nome e demais usuários ',
                    {
                      text: 'PLATAFORMA MYIPAC',
                      bold: true,
                    },
                    {
                      text: '(LICENCIADO)',
                      bold: true,
                    },
                    ' bem como senhas e emails, que são pessoais e intransferíveis. ',
                  ],
                },
                {
                  marginLeft: 23,
                  text: [
                    '2.6.	O ',
                    {
                      text: 'LICENCIADO',
                      bold: true,
                    },
                    ' será o único e exclusivo responsável por todas as atividades que ocorram durante a utilização de suas credenciais de acesso de cada usuário (chave e senha), ainda que indevidas, não podendo responsabilizar a ',
                    {
                      text: 'LICENCIANTE IPAC',
                      bold: true,
                    },
                    ' por qualquer invasão por hackers ou infestação por vírus, em suas máquinas, derivados da publicidade da senha por negligência, imprudência e/ou imperícia dos usuários do ',
                    {
                      text: 'LICENCIADO',
                      bold: true,
                    },
                    ', ou ainda, por dolo, coação ou simulação provocados por terceiros, ou ainda em casos fortuitos e força maior.',
                  ],
                },
                {
                  marginLeft: 23,
                  text: [
                    '2.7.	Ainda, na ocorrência de quaisquer das hipóteses da cláusula 2.6 acima, assim como de apresentação de dados inverídicos pelo ',
                    {
                      text: 'LICENCIADO',
                      bold: true,
                    },
                    ', o ',
                    {
                      text: 'LICENCIANTE IPAC',
                      bold: true,
                    },
                    ' poderá, a seu único e exclusivo critério, suspender ou cancelar imediatamente a conta de acesso do ',
                    {
                      text: 'LICENCIADO',
                      bold: true,
                    },
                    ', independentemente de notificação prévia, sem prejuízo de exigir indenização por perdas e danos decorrentes de eventual vazamento das informações contidas na ',
                    {
                      text: 'PLATAFORMA MYIPAC.',
                      bold: true,
                    },
                  ],
                },
                {
                  marginLeft: 23,
                  text: [
                    '2.8.	Fica o ',
                    {
                      text: 'LICENCIADO',
                      bold: true,
                    },
                    ' advertido que a ',
                    {
                      text: 'PLATAFORMA MYIPAC',
                      bold: true,
                    },
                    ' permite uma integração com algumas plataformas existentes no mercado, tais como, mas sem limitar, o WhatsApp, pelas quais é possível a contratação/aquisição, suporte, ativação e faturamento/cobrança diretamente com os fornecedores do ',
                    {
                      text: 'LICENCIADO',
                      bold: true,
                    },
                    '. Tal integração não implicará em qualquer responsabilização, seja de que natureza for, à ',
                    {
                      text: 'LICENCIADO',
                      bold: true,
                    },
                    ', tanto perante o ',
                    {
                      text: 'LICENCIADO',
                      bold: true,
                    },
                    ', quanto a terceiros, na medida em que a ferramenta possibilita apenas a integração, mas não as operações que serão realizadas pelo ',
                    {
                      text: 'LICENCIADO.',
                      bold: true,
                    },
                  ],
                },
                {
                  marginLeft: 23,
                  text: [
                    '2.9.	Os arquivos digitais do ',
                    {
                      text: 'LICENCIADO',
                      bold: true,
                    },
                    ' e os recibos de entrega à Receita Federal permanecerão disponíveis pelo período da vigência do Contrato e mediante a adimplência dos valores ajustados pelas ',
                    {
                      text: 'PARTES',
                      bold: true,
                    },
                    ' na FOLHA DE ROSTO.',
                  ],
                },
                {
                  text: '3.	III – DAS OBRIGAÇÕES DO LICENCIADO',
                  marginTop: 35,
                  bold: true,
                },
                {
                  marginLeft: 23,
                  text: [
                    '3.1.	O ',
                    {
                      text: 'LICENCIADO',
                      bold: true,
                    },
                    ' deverá utilizar a ',
                    {
                      text: 'PLATAFORMA MYIPAC',
                      bold: true,
                    },
                    ' de acordo com os termos deste Contrato, além de respeitar todas as normas legais aplicáveis ao produto.',
                  ],
                },
                {
                  marginLeft: 55,
                  text: [
                    '3.1.1.	O ',
                    {
                      text: 'LICENCIADO',
                      bold: true,
                    },
                    ' deverá manter os dados cadastrais, bem como a estrutura atual de pessoais com permissões de acesso à ferramenta atualizados.',
                  ],
                },
                {
                  marginLeft: 55,
                  text: [
                    '3.1.2.	Em caso de eventual mudança de endereço, deverá imediatamente comunicar a ',
                    {
                      text: 'LICENCIANTE IPAC',
                      bold: true,
                    },
                    ' para as devidas atualizações cadastrais.',
                  ],
                },
                {
                  marginLeft: 23,
                  text: [
                    '3.2.	O ',
                    {
                      text: 'LICENCIADO',
                      bold: true,
                    },
                    ' tem ciência e concorda, que para acessar e utilizar em sua plenitude a ',
                    {
                      text: 'PLATAFORMA MYIPAC',
                      bold: true,
                    },
                    ' objeto do presente licenciamento, deverá dispor previamente de equipamentos para acesso à internet, bem como, infraestrutura tecnológica, que obedeça aos requisitos mínimos para a fiel execução da ',
                    {
                      text: 'PLATAFORMA MYIPAC',
                      bold: true,
                    },
                    ', conforme disposto na',
                    {
                      text: ' FOLHA DE ROSTO',
                      bold: true,
                    },
                  ],
                },
                {
                  marginLeft: 55,
                  text: [
                    '3.2.1.	O ',
                    {
                      text: 'LICENCIADO',
                      bold: true,
                    },
                    ' se obriga a atualizar todos os seus equipamentos informáticos, sob pena de perder as evoluções tecnológicas implementadas no ',
                    {
                      text: 'PLATAFORMA MYIPAC',
                      bold: true,
                    },
                    ', bem como, atualizações, passando não obter os benefícios oferecidos pela ',
                    {
                      text: 'PLATAFORMA MYIPAC.',
                      bold: true,
                    },
                  ],
                },
                {
                  marginLeft: 23,
                  text: [
                    '3.3.	Se acaso verificar alguma situação anormal ou instabilidade da ',
                    {
                      text: 'PLATAFORMA MYIPAC',
                      bold: true,
                    },
                    ', o ',
                    {
                      text: 'LICENCIADO',
                      bold: true,
                    },
                    ' deverá notificar o mais breve possível a ',
                    {
                      text: 'LICENCIANTE IPAC',
                      bold: true,
                    },
                    ', para que as medidas de estabilização sejam tomadas.',
                  ],
                },
                {
                  marginLeft: 23,
                  text: [
                    '3.4.	Ocorrendo alguma perda de informações atribuível ao ',
                    {
                      text: 'LICENCIADO',
                      bold: true,
                    },
                    ', não poderá ser responsabilizada a ',
                    {
                      text: 'LICENCIANTE IPAC',
                      bold: true,
                    },
                    ' por quaisquer indenizações oriundas de eventuais danos diretos ou indiretos decorrentes.',
                  ],
                },
                {
                  marginLeft: 23,
                  text: [
                    '3.5.	O ',
                    {
                      text: 'LICENCIADO',
                      bold: true,
                    },
                    ' concorda e se obriga a:',
                  ],
                },
                {
                  marginLeft: 55,
                  text: [
                    '3.5.1.	Informar imediatamente a ',
                    {
                      text: 'LICENCIANTE IPAC',
                      bold: true,
                    },
                    ' de qualquer uso não autorizado do login ou da senha de acesso a ',
                    {
                      text: 'PLATAFORMA MYIPAC',
                      bold: true,
                    },
                  ],
                },
                {
                  marginLeft: 55,
                  text: [
                    '3.5.2.	Manter-se assegurado de que a ',
                    {
                      text: 'PLATAFORMA MYIPAC',
                      bold: true,
                    },
                    ' não seja acessado por terceiros não autorizados, não podendo, inclusive, mas sem limitação, fornecer, franquear ou disponibilizar o login e senha para terceiros, salvo se previamente autorizado pela ',
                    {
                      text: 'LICENCIANTE IPAC.',
                      bold: true,
                    },
                  ],
                },
                {
                  marginLeft: 23,
                  text: [
                    '3.6.	Eventuais cópias de segurança (backup) baixadas do banco de dados da nuvem, serão de responsabilidade única e exclusiva do ',
                    {
                      text: 'LICENCIADO.',
                      bold: true,
                    },
                  ],
                },
                {
                  marginLeft: 23,
                  text: [
                    '3.7.	O ',
                    {
                      text: 'LICENCIADO',
                      bold: true,
                    },
                    ' declara estar ciente que pequenos defeitos de programação (bugs) são comuns a todos os programas de computador, isentando, assim, a ',
                    {
                      text: 'LICENCIANTE IPAC',
                      bold: true,
                    },
                    ', de qualquer responsabilidade por danos decorrentes de tais bugs usuais, limitando a responsabilidade da ',
                    {
                      text: 'LICENCIANTE IPAC',
                      bold: true,
                    },
                    ' à correção desses pequenos defeitos de programação.',
                  ],
                },
                {
                  marginLeft: 23,
                  text: [
                    '3.8.	O ',
                    {
                      text: 'LICENCIADO',
                      bold: true,
                    },
                    ' não poderá ter acesso aos códigos fonte da ',
                    {
                      text: 'PLATAFORMA MYIPAC',
                      bold: true,
                    },
                    ', nem a camadas fechadas da ',
                    {
                      text: 'PLATAFORMA MYIPAC',
                      bold: true,
                    },
                    ', ficando ciente que a tentativa de acesso a esses itens, ou o efetivo acesso a esses itens, por qualquer meio, constituirá violação de direitos autorais do titular dos direitos sobre a ',
                    {
                      text: 'PLATAFORMA MYIPAC',
                      bold: true,
                    },
                    ', sujeitando o ',
                    {
                      text: 'LICENCIADO',
                      bold: true,
                    },
                    ' às sanções previstas em Lei.',
                  ],
                },
                {
                  marginLeft: 23,
                  text: [
                    '3.9.	O ',
                    {
                      text: 'LICENCIADO',
                      bold: true,
                    },
                    ' não poderá, direta ou indiretamente, inclusive através de atos de seus prepostos, copiar, modificar, transferir, sublicenciar, ceder, vender, dar em locação, dar em garantia, doar, ou alienar a terceiros a ',
                    {
                      text: 'PLATAFORMA MYIPAC',
                      bold: true,
                    },
                    ', sob qualquer forma ou modalidade, gratuita ou onerosa, provisória ou permanente, no todo ou em parte, tampouco submetê-lo a processos de engenharia reversa ou, ainda, traduzi-lo ou decodificá-lo, no todo ou em parte. As mesmas vedações aplicam-se a quaisquer documentações ou informações relativas a ',
                    {
                      text: 'PLATAFORMA MYIPAC',
                      bold: true,
                    },
                  ],
                },
                {
                  marginLeft: 23,
                  text: [
                    '3.10.	O ',
                    {
                      text: 'LICENCIADO',
                      bold: true,
                    },
                    ' se compromete a não utilizar as especificações da ',
                    {
                      text: 'PLATAFORMA MYIPAC',
                      bold: true,
                    },
                    ', ou permitir que terceiros as utilizem, com vistas a criar outros de mesma destinação',
                  ],
                },
                {
                  marginLeft: 23,
                  text: [
                    '3.11.	Na possibilidade de alguma atualização ou nova versão da ',
                    {
                      text: 'PLATAFORMA MYIPAC',
                      bold: true,
                    },
                    ' venha a exigir novas especificações técnicas que o ',
                    {
                      text: 'LICENCIADO',
                      bold: true,
                    },
                    ' configure sua infraestrutura de, por exemplo, mas não limitando, hardware, sistema operacional, gerenciador de banco de dados, rede e internet, os upgrades ficarão a cargo único e exclusivo do ',
                    {
                      text: 'LICENCIADO',
                      bold: true,
                    },
                    ', vedada a cobrança dirigida à ',
                    {
                      text: 'LICENCIANTE IPAC',
                      bold: true,
                    },
                    ', em reembolso, compensação, indenização, ou qualquer outra modalidade possível.',
                  ],
                },
                {
                  marginLeft: 23,
                  text: [
                    '3.12.	Apesar da ',
                    {
                      text: 'LICENCIANTE IPAC',
                      bold: true,
                    },
                    ', desde já, envidar os melhores esforços para disponibilizar a ',
                    {
                      text: 'PLATAFORMA MYIPAC',
                      bold: true,
                    },
                    ' para utilização do ',
                    {
                      text: 'LICENCIADO',
                      bold: true,
                    },
                    ', livre de incorreções e falhas, sabe-se tratar de produto genérico, ou seja, não encomendado especialmente pelo ',
                    {
                      text: 'LICENCIADO.',
                      bold: true,
                    },
                  ],
                },
                {
                  marginLeft: 55,
                  text: [
                    '3.12.1.	Assim, eventuais faltas de funcionalidades que atendam às necessidades específicas do ',
                    {
                      text: 'LICENCIADO',
                      bold: true,
                    },
                    ', não significará a obrigatoriedade da ',
                    {
                      text: 'LICENCIANTE IPAC',
                      bold: true,
                    },
                    ' em implementar as atualizações ou alterações na ',
                    {
                      text: 'PLATAFORMA MYIPAC',
                      bold: true,
                    },
                    ', tampouco implicará no direito do ',
                    {
                      text: 'LICENCIADO',
                      bold: true,
                    },
                    ' em solicitar abatimento, desconto ou supressão dos pagamentos ajustados pela licença',
                  ],
                },
                {
                  marginLeft: 55,
                  text: [
                    '3.12.2.	Igualmente não assistirá ao ',
                    {
                      text: 'LICENCIADO',
                      bold: true,
                    },
                    ' qualquer direito a abatimento, desconto ou supressão dos pagamentos ajustados pela licença, por paralisações verificadas no sinal de internet contratada, sempre a cargo do ',
                    {
                      text: 'LICENCIADO.',
                      bold: true,
                    },
                  ],
                },
                {
                  marginLeft: 23,
                  text: [
                    '3.13.	O ',
                    {
                      text: 'LICENCIADO',
                      bold: true,
                    },
                    ' declara que lhe foram demonstradas previamente as características e funcionalidades da ',
                    {
                      text: 'PLATAFORMA MYIPAC',
                      bold: true,
                    },
                    ', não lhe assistindo o direito de reclamar pela falta de informações adequadas.',
                  ],
                },
                {
                  text: '4.	IV – DAS OBRIGAÇÕES DA LICENCIANTE IPAC',
                  marginTop: 35,
                  bold: true,
                },
                {
                  marginLeft: 23,
                  text: [
                    '4.1.	A ',
                    {
                      text: 'LICENCIANTE IPAC',
                      bold: true,
                    },
                    ' se obriga a disponibilizar ao ',
                    {
                      text: 'LICENCIADO',
                      bold: true,
                    },
                    ' a ',
                    {
                      text: 'PLATAFORMA MYIPAC',
                      bold: true,
                    },
                    ', conforme as obrigações previstas nesse Contrato.',
                  ],
                },
                {
                  marginLeft: 23,
                  text: [
                    '4.2.	A ',
                    {
                      text: 'LICENCIANTE IPAC',
                      bold: true,
                    },
                    ' se compromete a:',
                  ],
                },
                {
                  marginLeft: 55,
                  text: [
                    '4.2.1.	Prestar com continuidade o serviço objeto deste Contrato, inclusive com o recebimento de novas versões, melhorias e aperfeiçoamentos relacionados a ',
                    {
                      text: 'PLATAFORMA MYIPAC',
                      bold: true,
                    },
                    ', desde que receba mensalmente os valores da licença, conforme a ',
                    {
                      text: 'FOLHA DE ROSTO.',
                      bold: true,
                    },
                  ],
                },
                {
                  marginLeft: 55,
                  text: [
                    '4.2.2.	Prestar suporte técnico diretamente, ou por meio de sua rede de franqueados, ao ',
                    {
                      text: 'LICENCIADO',
                      bold: true,
                    },
                    ', e-mail ou via chat, com a finalidade única de orientar a utilização e o manuseio da ',
                    {
                      text: 'PLATAFORMA MYIPAC.',
                      bold: true,
                    },
                  ],
                },
                {
                  marginLeft: 100,
                  text: [
                    '4.2.2.1.	Se necessário o suporte técnico poderá ser efetuado pela ',
                    {
                      text: 'LICENCIANTE IPAC',
                      bold: true,
                    },
                    ' acessando remotamente o computador dos usuários do ',
                    {
                      text: 'LICENCIADO',
                      bold: true,
                    },
                    ' via internet, com o que concorda e anui desde já o ',
                    {
                      text: 'LICENCIADO.',
                      bold: true,
                    },
                  ],
                },
                {
                  marginLeft: 100,
                  text: [
                    '4.2.2.2.	Também, se necessário, a ',
                    {
                      text: 'LICENCIANTE',
                      bold: true,
                    },
                    ' efetuará uma cópia da base de dados do ',
                    {
                      text: 'LICENCIADO',
                      bold: true,
                    },
                    ' para análise, simulação e repetição de possíveis inconsistências em seu laboratório, garantindo total sigilo sobre todas as informações constantes da base de dados.',
                  ],
                },
                {
                  marginLeft: 100,
                  text: [
                    '4.2.2.3.	Caso seja prestado suporte técnico presencial pela ',
                    {
                      text: 'LICENCIANTE IPAC',
                      bold: true,
                    },
                    ' ou por sua rede de franqueados, haverá cobrança pelo deslocamento e pelo tempo necessário para o atendimento, de acordo com a tabela de valores do deslocamento e de horas pelo serviço técnico vigente à época do atendimento.',
                  ],
                },
                {
                  marginLeft: 23,
                  text: [
                    '4.3.	A descrição dos módulos da ',
                    {
                      text: 'PLATAFORMA MYIPAC',
                      bold: true,
                    },
                    ' disponibilizado ao ',
                    {
                      text: 'LICENCIADO',
                      bold: true,
                    },
                    ' está informada em Dados dos Produtos/Serviços Contratados, e os mesmos deverão ser atualizados continuamente, enquanto o ',
                    {
                      text: 'LICENCIADO',
                      bold: true,
                    },
                    ' estiver com a subscrição ativa. Caso a ',
                    {
                      text: 'LICENCIANTE IPAC',
                      bold: true,
                    },
                    ' venha a lançar algum outro módulo e ou Addons, os mesmos poderão ser vendidos de forma adicionais ao ',
                    {
                      text: 'LICENCIADO.',
                      bold: true,
                    },
                  ],
                },
                {
                  marginLeft: 23,
                  text: [
                    '4.4.	Caso o ',
                    {
                      text: 'LICENCIADO',
                      bold: true,
                    },
                    ' discorde do tratamento legal efetuado pela ',
                    {
                      text: 'PLATAFORMA MYIPAC',
                      bold: true,
                    },
                    ', deverá enviar à ',
                    {
                      text: 'LICENCIANTE IPAC',
                      bold: true,
                    },
                    ' as explicações via e- mail, acompanhado da legislação que fundamente a discordância, e somente após essa providência, haverá a análise da questão pela ',
                    {
                      text: 'LICENCIANTE IPAC.',
                      bold: true,
                    },
                  ],
                },
                {
                  marginLeft: 23,
                  text: [
                    '4.5.	A ',
                    {
                      text: 'LICENCIANTE IPAC',
                      bold: true,
                    },
                    ' poderá livremente incluir, alterar ou excluir telas, rotinas, relatórios e procedimentos da ',
                    {
                      text: 'PLATAFORMA MYIPAC',
                      bold: true,
                    },
                    ' sem aviso prévio, visando sempre a melhoria continua da ferramenta.',
                  ],
                },
                {
                  marginLeft: 55,
                  text: [
                    '4.5.1.	A par do domínio da ',
                    {
                      text: 'PLATAFORMA MYIPAC',
                      bold: true,
                    },
                    ', tanto as melhorias e novas funcionalidade implementadas por iniciativa da ',
                    {
                      text: 'LICENCIANTE IPAC',
                      bold: true,
                    },
                    ', quanto aquelas sugeridas pelo ',
                    {
                      text: 'LICENCIADO',
                      bold: true,
                    },
                    ', continuarão a pertencer na íntegra à ',
                    {
                      text: 'LICENCIANTE IPAC',
                      bold: true,
                    },
                    ', não fazendo jus o ',
                    {
                      text: 'LICENCIADO',
                      bold: true,
                    },
                    ' a qualquer direito, seja de que natureza for, a tais implementações.',
                  ],
                },
                {
                  text: '5.	V – DO PREÇO PELA CONTRATAÇÃO DA LICENÇA',
                  marginTop: 35,
                  bold: true,
                },
                {
                  marginLeft: 23,
                  text: [
                    '5.1.	Para a licença da ',
                    {
                      text: 'PLATAFORMA MYIPAC',
                      bold: true,
                    },
                    ', o ',
                    {
                      text: 'LICENCIADO',
                      bold: true,
                    },
                    ' pagará à ',
                    {
                      text: 'LICENCIANTE IPAC',
                      bold: true,
                    },
                    ' o valor da licença de uso e manutenção da ',
                    {
                      text: 'PLATAFORMA MYIPAC',
                      bold: true,
                    },
                    ', mediante condições de pagamento previstas na ',
                    {
                      text: 'FOLHA DE ROSTO',
                      bold: true,
                    },
                    ', na forma ali ajustada pelas ',
                    {
                      text: 'PARTES.',
                      bold: true,
                    },
                  ],
                },
                {
                  marginLeft: 23,
                  text: [
                    '5.2.	O atraso no pagamento de quaisquer mensalidades implicará na possibilidade da ',
                    {
                      text: 'LICENCIANTE IPAC',
                      bold: true,
                    },
                    ' exigir o cumprimento da obrigação, acrescida de multa de 2% (dois por cento), correção monetária a ser calculada pelo IGP-M (Fundação Getúlio Vargas) ou outro que o substitua, além e juros de mora de 1% (um por cento) ao mês, até a data do efetivo',
                  ],
                },
                {
                  marginLeft: 23,
                  text: [
                    '5.3.	Em caso de inadimplência do ',
                    {
                      text: 'LICENCIADO',
                      bold: true,
                    },
                    ' verificada por período superior a 20 (vinte) dias contados do vencimento original da mensalidade, a ',
                    {
                      text: 'LICENCIANTE IPAC',
                      bold: true,
                    },
                    ' poderá a seu único e exclusivo critério, suspender ou cancelar a licença da ',
                    {
                      text: 'PLATAFORMA MYIPAC',
                      bold: true,
                    },
                    ', resilindo o presente Contrato de pleno direito e bloqueando o acesso de todos os usuários do ',
                    {
                      text: 'LICENCIADO',
                      bold: true,
                    },
                    ' ou parte dos usuários do ',
                    {
                      text: 'LICENCIADO',
                      bold: true,
                    },
                    ' à plataforma, sem prejuízo ao envio do título a protesto, com inclusão do nome do ',
                    {
                      text: 'LICENCIADO',
                      bold: true,
                    },
                    ' nos serviços de proteção ao crédito, além de ingressar com a medidas judiciais cabíveis, nesse caso com o acréscimo honorários advocatícios prefixados em 20% (vinte por cento) sobre o total do débito.',
                  ],
                },
                {
                  marginLeft: 23,
                  text: [
                    '5.4.	O ',
                    {
                      text: 'LICENCIADO',
                      bold: true,
                    },
                    ' tem ciência e concorda que a suspensão ou cancelamento da licença da ',
                    {
                      text: 'PLATAFORMA MYIPAC',
                      bold: true,
                    },
                    ', por inadimplência, não lhe exime do pagamento das parcelas em aberto até a efetiva resilição contratual.',
                  ],
                },
                {
                  marginLeft: 23,
                  text: [
                    '5.5.	O valor da mensalidade será reajustado pela ',
                    {
                      text: 'LICENCIANTE IPAC',
                      bold: true,
                    },
                    ' anualmente, contado da assinatura do Contrato, com base no IGP-M (Fundação Getúlio Vargas), ou outro que o substitua, e desde que verificada variação positiva, sendo que, do contrário, restará ao ',
                    {
                      text: 'LICENCIADO',
                      bold: true,
                    },
                    ` permanecer quitando os mesmos valores das últimas ${installmentsExtense} mensalidades ou na periodicidade mínima determinada pela legislação.`,
                  ],
                },
                {
                  marginLeft: 55,
                  text: [
                    '5.5.1.	Se a legislação vier a permitir uma periodicidade menor que a anual, atualmente determinada para o reajuste das mensalidades, a ',
                    {
                      text: 'LICENCIANTE IPAC',
                      bold: true,
                    },
                    ' poderá imediatamente praticar neste Contrato, o novo período de reajuste mínimo.',
                  ],
                },
                {
                  marginLeft: 23,
                  text: '5.6.	Todos os tributos devidos pela presente contratação já estão incluídos nas mensalidades.',
                },
                {
                  text: '6.	VI - DA VIGÊNCIA',
                  marginTop: 35,
                  bold: true,
                },
                {
                  marginLeft: 23,
                  text: [
                    `6.1.	O presente Contrato terá duração de ${installmentsExtense} meses, renovando-se automaticamente por prazo indeterminado, salvo se alguma das `,
                    {
                      text: 'PARTES',
                      bold: true,
                    },
                    ', por escrito e com antecedência de 60 (sessenta) dias, denunciar a intenção em rescindir o Contrato, hipótese que não ensejará quaisquer penalidades ou encargos.',
                  ],
                },
                {
                  marginLeft: 23,
                  text: [
                    '6.2.	Na hipótese de denúncia do Contrato durante o prazo indeterminado, a ',
                    {
                      text: 'PARTE',
                      bold: true,
                    },
                    ' que a fizer, deverá notificar a outra, mediante pré-aviso de 90 (noventa) dias anteriores ao efetivo término da relação contratual entre as ',
                    {
                      text: 'PARTES.',
                      bold: true,
                    },
                  ],
                },
                {
                  marginLeft: 23,
                  text: [
                    '6.3.	A rescisão do Contrato não exime o ',
                    {
                      text: 'LICENCIADO',
                      bold: true,
                    },
                    ' de quitar as mensalidades até então vencidas e porventura não pagas.',
                  ],
                },
                {
                  marginLeft: 23,
                  text: [
                    '6.4.	Fica ressalvado que as ofertas e as campanhas promocionais serão válidas somente pelo período inicial de vigência do Contrato, podendo ou não serem renovadas ao final da respectiva vigência, a critério exclusivo da ',
                    {
                      text: 'LICENCIANTE IPAC.',
                      bold: true,
                    },
                  ],
                },
                {
                  marginLeft: 23,
                  text: [
                    '6.5.	O presente Contrato poderá ser rescindido pela ',
                    {
                      text: 'LICENCIANTE IPAC',
                      bold: true,
                    },
                    ', de pleno direito, independentemente de qualquer notificação ou interpelação judicial ou extrajudicial, sem prejuízo das sanções contratuais e/ou legais e eventual indenização por perdas e danos, em qualquer das seguintes hipóteses:',
                  ],
                },
                {
                  marginLeft: 55,
                  text: [
                    'i.	O descumprimento, total ou parcial, de qualquer cláusula, condição ou disposição do presente Contrato pelo ',
                    {
                      text: 'LICENCIADO;',
                      bold: true,
                    },
                  ],
                },
                {
                  marginLeft: 55,
                  text: [
                    'ii.	A prática de atos pelo ',
                    {
                      text: 'LICENCIADO',
                      bold: true,
                    },
                    ' que importem em descrédito ou afetem a imagem da ',
                    {
                      text: 'LICENCIANTE IPAC;',
                      bold: true,
                    },
                  ],
                },
                {
                  marginLeft: 55,
                  text: [
                    'iii.	A decretação de falência ou a recuperação judicial do ',
                    {
                      text: 'LICENCIADO',
                      bold: true,
                    },
                    ', além de sua dissolução judicial ou extrajudicial;',
                  ],
                },
                {
                  marginLeft: 55,
                  text: [
                    'iv.	A ocorrência de força maior que se prolongue por determinado período que, ao entendimento da ',
                    {
                      text: 'LICENCIANTE IPAC',
                      bold: true,
                    },
                    ', impossibilite a continuidade da prestação dos serviços objeto do Contrato.',
                  ],
                },
                {
                  text: '7.	VII– DAS LIMITAÇÕES DE RESPONSABILIDADE',
                  marginTop: 35,
                  bold: true,
                },
                {
                  marginLeft: 23,
                  text: [
                    '7.1.	Na máxima extensão permitida pela legislação aplicável, e observada a Cláusula 7.2, as ',
                    {
                      text: 'PARTES',
                      bold: true,
                    },
                    ' entendem e concordam que o valor máximo de indenização que a ',
                    {
                      text: 'LICENCIANTE IPAC',
                      bold: true,
                    },
                    ` estará sujeita por quaisquer danos decorrentes ou relacionados a este Contrato não poderá exceder a quantia correspondente a 30% (trinta por cento) do valor total do período de ${installmentsExtense} mensalidades pagas, vigente à época dos danos.`,
                  ],
                },
                {
                  marginLeft: 23,
                  text: [
                    '7.2.	Não obstante a Cláusula 1. acima, fica expressamente convencionado entre as ',
                    {
                      text: 'PARTES',
                      bold: true,
                    },
                    ' que a ',
                    {
                      text: 'LICENCIANTE IPAC',
                      bold: true,
                    },
                    ' não será de qualquer forma responsabilizada por perdas e danos indiretos, consequentes, incidentais, punitivos ou especiais, tais como, por exemplo, mas não limitando, lucros cessantes, perdas de negócios ou de receitas, e responsabilidade perante terceiros.',
                  ],
                },
                {
                  marginLeft: 23,
                  text: [
                    '7.3.	O ',
                    {
                      text: 'LICENCIADO',
                      bold: true,
                    },
                    ' está ciente e concorda expressamente que a ',
                    {
                      text: 'PLATAFORMA MYIPAC',
                      bold: true,
                    },
                    ' é de alta disponibilidade, mas é comum e inerente à natureza de qualquer software, sobretudo os processados em ambientes “cloud”, em nuvem, a superveniência de erros e falhas técnicas eventuais, decorrentes da infraestrutura de comunicação da rede mundial de computadores, atualizações tecnológicas, disponibilidade e comunicação na internet, entre outros fatores. ',
                  ],
                },
                {
                  marginLeft: 23,
                  text: [
                    '7.4.	Não obstante, a ',
                    {
                      text: 'LICENCIANTE IPAC',
                      bold: true,
                    },
                    ' envidará esforços para assegurar o bom funcionamento da ',
                    {
                      text: 'PLATAFORMA MYIPAC',
                      bold: true,
                    },
                    ', sobretudo mediante o lançamento de novas versões e módulos',
                  ],
                },
                {
                  marginLeft: 23,
                  text: [
                    '7.5.	O ',
                    {
                      text: 'LICENCIADO',
                      bold: true,
                    },
                    ' entende e concorda em não responsabilizar a ',
                    {
                      text: 'LICENCIANTE IPAC',
                      bold: true,
                    },
                    ' pelo não funcionamento, ou pelo funcionamento inadequado da ',
                    {
                      text: 'PLATAFORMA MYIPAC',
                      bold: true,
                    },
                    ' em decorrência da não adoção pelo ',
                    {
                      text: 'LICENCIADO',
                      bold: true,
                    },
                    ' dos requisitos mínimos descritos nesta proposta, por falhas na rede de computadores interna do ',
                    {
                      text: 'LICENCIADO',
                      bold: true,
                    },
                    ' ou por falhas nos navegadores ou no serviço de internet, todos de responsabilidade única e exclusiva do ',
                    {
                      text: 'LICENCIADO.',
                      bold: true,
                    },
                  ],
                },
                {
                  marginLeft: 23,
                  text: [
                    '7.6.	Diante das informações expostas, a ',
                    {
                      text: 'LICENCIANTE IPAC',
                      bold: true,
                    },
                    ' não poderá ser responsabilizada por quaisquer danos de quaisquer naturezas, diretos ou indiretos, incidentais ou consequentes, ou ainda relativos a lucros cessantes, perda de receitas ou de dados, incorridos em virtude da utilização da ',
                    {
                      text: 'PLATAFORMA MYIPAC',
                      bold: true,
                    },
                    ' e/ou dos resultados produzidos por este, pelo ',
                    {
                      text: 'LICENCIADO',
                      bold: true,
                    },
                    ' ou por quaisquer terceiros.',
                  ],
                },
                {
                  text: '8.	VIII– DA CONFIDENCIALIDADE',
                  marginTop: 35,
                  bold: true,
                },
                {
                  marginLeft: 23,
                  text: [
                    '8.1.	Os dados e informações constantes neste Contrato e na ',
                    {
                      text: 'FOLHA DE ROSTO',
                      bold: true,
                    },
                    ' são de uso e conhecimento exclusivo das ',
                    {
                      text: 'PARTES',
                      bold: true,
                    },
                    ', não podendo ser utilizados como fonte de informações para terceiros, salvo as já publicadas ou aquelas que o ',
                    {
                      text: 'LICENCIANTE IPAC',
                      bold: true,
                    },
                    ' precise utilizar para comunicação, negociação, contratação, pagamento, cobrança e prestação dos serviços estabelecido neste Contrato, podendo nesses casos o ',
                    {
                      text: 'LICENCIANTE IPAC',
                      bold: true,
                    },
                    ' compartilha-las com empresas operadoras bancárias, de cartões de crédito e de meios de pagamento e de emissão de notas fiscais.',
                  ],
                },
                {
                  marginLeft: 23,
                  text: [
                    '8.2.	A ',
                    {
                      text: 'LICENCIANTE IPAC',
                      bold: true,
                    },
                    ' cumprirá o disposto na Lei nº 965/2014 (Marco Civil da Internet), quanto à proteção de dados pessoais do ',
                    {
                      text: 'LICENCIADO',
                      bold: true,
                    },
                    ', naquilo que for cabível, responsabilizando-se pela conservação dos dados alimentados pelo ',
                    {
                      text: 'LICENCIADO',
                      bold: true,
                    },
                    ', que serão conservados durante toda a vigência do presente Contrato e pelo prazo mínimo de 6 (seis) meses após a rescisão contratual, às quais poderão ser disponibilizados pela ',
                    {
                      text: 'LICENCIANTE IPAC',
                      bold: true,
                    },
                    ' ao ',
                    {
                      text: 'LICENCIADO',
                      bold: true,
                    },
                    ', mediante autorização judicial, conforme determina o artigo 15 e parágrafos da referida lei.',
                  ],
                },
                {
                  marginLeft: 23,
                  text: [
                    '8.3.	O ',
                    {
                      text: 'LICENCIADO',
                      bold: true,
                    },
                    ' desde já manifesta a autorização de compartilhamento dos seus dados pessoais, além da utilização de seu nome pela ',
                    {
                      text: 'LICENCIANTE IPAC',
                      bold: true,
                    },
                    ', outras sociedades cujas participações societárias forem integralmente detidas pelos mesmos quotistas indiretos da ',
                    {
                      text: 'LICENCIANTE IPAC',
                      bold: true,
                    },
                    ' ou cuja administração seja confiada à mesma sociedade de gestão de investimentos dos quotistas indiretos da ',
                    {
                      text: 'LICENCIANTE IPAC',
                      bold: true,
                    },
                    ', assim como pela sua rede de franqueados, sem que tenha quaisquer direitos a remunerações, de quem quer que seja, podendo a ',
                    {
                      text: 'LICENCIANTE IPAC',
                      bold: true,
                    },
                    ' apresentá-lo como cliente em quaisquer meios de divulgação, a seu exclusivo critério.',
                  ],
                },
                {
                  marginLeft: 23,
                  text: [
                    '8.4.	Ao aprovar esta proposta, o ',
                    {
                      text: 'LICENCIADO',
                      bold: true,
                    },
                    ' entende e autoriza que os dados fornecidos no TERMO DE ACEITE sejam utilizados exclusivamente para a contratação, habilitação do ',
                    {
                      text: 'LICENCIADO',
                      bold: true,
                    },
                    ' na ',
                    {
                      text: 'PLATAFORMA MYIPAC',
                      bold: true,
                    },
                    ' e comunicações relacionadas ao produto e seu ecossistema, podendo o ',
                    {
                      text: 'LICENCIADO',
                      bold: true,
                    },
                    ', após a rescisão contratual, solicitar a qualquer tempo a exclusão de suas informações da base de dados. (mailto: suporte@myipac.com.br)',
                  ],
                },
                {
                  marginLeft: 23,
                  text: [
                    '8.5.	A presente cláusula de confidencialidade obriga não apenas as ',
                    {
                      text: 'PARTES',
                      bold: true,
                    },
                    ', como também seus sucessores a qualquer título, coligadas, controladoras, controladas e cessionárias devidamente autorizadas, bem como seus respectivos sócios, administradores, empregados, prepostos e subcontratados.',
                  ],
                },
                {
                  marginLeft: 23,
                  text: [
                    '8.6.	Na execução do presente Contrato, as ',
                    {
                      text: 'PARTES',
                      bold: true,
                    },
                    ' se obrigam a observar a legislação aplicável em relação a proteção de dados pessoais, incluindo, mas não se limitando à Lei Geral de Proteção de Dados Pessoais brasileira, Lei nº 709/18 (“LGPD”).',
                  ],
                },
                {
                  text: '9.	IX – DAS DISPOSIÇÕES GERAIS',
                  marginTop: 35,
                  bold: true,
                },
                {
                  marginLeft: 23,
                  text: [
                    '9.1.	Toda e qualquer notificação extrajudicial, a ser efetuada entre as ',
                    {
                      text: 'PARTES',
                      bold: true,
                    },
                    ', poderá ser realizada via carta registrada, e-mail, ou outra forma tecnologicamente permitida, desde que ateste o efetivo recebimento da outra, cuidando o notificante de guardar consigo a prova da entrega.',
                  ],
                },
                {
                  marginLeft: 23,
                  text: [
                    '9.2.	O presente Contrato, bem como, outros documentos que se façam necessários no decorrer da relação contratual entre as ',
                    {
                      text: 'PARTES',
                      bold: true,
                    },
                    ', não poderá ser cedido e/ou transferido no todo ou em parte pelo ',
                    {
                      text: 'LICENCIADO',
                      bold: true,
                    },
                    ' sem o prévio e por escrito consentimento da ',
                    {
                      text: 'LICENCIANTE IPAC',
                      bold: true,
                    },
                    `, sob pena de resilição de pleno direito do Contrato, com aplicação de multa de 10% (dez por cento) sobre o valor total da contratação para o período de ${installmentsExtense} meses.`,
                  ],
                },
                {
                  marginLeft: 55,
                  text: [
                    '9.2.1.	 Já a ',
                    {
                      text: 'LICENCIANTE IPAC',
                      bold: true,
                    },
                    ', livremente poderá ceder o presente Contrato e demais documentos que se façam necessários no decorrer da relação contratual entre as ',
                    {
                      text: 'PARTES',
                      bold: true,
                    },
                    ', para qualquer sociedade controlada, controladora, de controle comum ou terceiros.',
                  ],
                },
                {
                  marginLeft: 55,
                  text: [
                    '9.2.2.	 Uma vez realizada a transferência da titularidade do Contrato por parte do ',
                    {
                      text: 'LICENCIADO',
                      bold: true,
                    },
                    ', nos termos da Cláusula 2. acima, a ',
                    {
                      text: 'LICENCIANTE IPAC',
                      bold: true,
                    },
                    ' realizará a transmissão de todas as orientações e procedimentos necessários, sendo que, se não observadas pelo ',
                    {
                      text: 'LICENCIADO',
                      bold: true,
                    },
                    ' ou pelo cedido, a transferência de titularidade em questão será considerada pela ',
                    {
                      text: 'LICENCIANTE IPAC',
                      bold: true,
                    },
                    ' como irregular, com a possibilidade aplicação da penalidade prevista na Cláusula 9.2 do Contrato.',
                  ],
                },
                {
                  marginLeft: 23,
                  text: [
                    '9.3.	A ',
                    {
                      text: 'FOLHA DE ROSTO',
                      bold: true,
                    },
                    ', o presente Contrato e seus anexos, prevalecerão sobre toda e qualquer negociação verbal ou documento eventualmente trocado pelas ',
                    {
                      text: 'PARTES',
                      bold: true,
                    },
                    ', sendo que, ter-se-ão por válidos os documentos acima descritos devidamente firmados pelos legítimos representantes legais das ',
                    {
                      text: 'PARTES.',
                      bold: true,
                    },
                  ],
                },
                {
                  marginLeft: 23,
                  text: [
                    '9.4.	O ',
                    {
                      text: 'LICENCIADO',
                      bold: true,
                    },
                    ' declara que teve amplo acesso à explicação de todas as Cláusulas contidas neste e em outros eventuais documentos firmados pelas ',
                    {
                      text: 'PARTES',
                      bold: true,
                    },
                    ', além da ',
                    {
                      text: 'PLATAFORMA MYIPAC',
                      bold: true,
                    },
                    ' licenciado e demais produtos da ',
                    {
                      text: 'LICENCIANTE IPAC',
                      bold: true,
                    },
                    ', não podendo alegar ignorância ou até hipossuficiência em relação a eles.',
                  ],
                },
                {
                  marginLeft: 23,
                  text: [
                    '9.5.	Nenhuma das ',
                    {
                      text: 'PARTES',
                      bold: true,
                    },
                    ' será considerada responsável pelo inadimplemento de qualquer obrigação da outra parte, seja ela de natureza pública ou privada.',
                  ],
                },
                {
                  marginLeft: 23,
                  text: [
                    '9.6.	A tolerância de qualquer uma das ',
                    {
                      text: 'PARTES',
                      bold: true,
                    },
                    ', em relação a eventuais infrações da outra, não importará em modificação contratual, novação ou renúncia a direito, devendo ser considerada mera liberalidade da citada ',
                    {
                      text: 'PARTE.',
                      bold: true,
                    },
                  ],
                },
                {
                  marginLeft: 23,
                  text: [
                    '9.7.	Se qualquer uma das disposições do presente Contrato for ou vier a tornar-se nula ou revelar-se omissa, tal nulidade ou omissão não afetará a validade das demais disposições deste. Nesse caso, as ',
                    {
                      text: 'PARTES',
                      bold: true,
                    },
                    ' envidarão esforços no sentido de estabelecer normas que mais se aproximem, quanto ao resultado econômico, da(s) disposição(ões) a ser(em) alterada(s) ou eliminada(s).',
                  ],
                },
                {
                  marginLeft: 23,
                  text: [
                    '9.8.	Uma vez ativada a licença, qualquer alteração do CNPJ do ',
                    {
                      text: 'LICENCIADO',
                      bold: true,
                    },
                    ' poderá ser realizada após 1 (um) mês contado da data da ativação da respectiva licença.',
                  ],
                },
                {
                  text: '10.	X– DO FORO',
                  marginTop: 35,
                  bold: true,
                },
                {
                  marginLeft: 23,
                  text: [
                    '10.1.	As ',
                    {
                      text: 'PARTES',
                      bold: true,
                    },
                    ' elegem o foro de Brasília (DF) para dirimir quaisquer dúvidas ou julgar quaisquer litígios oriundos deste Contrato, com renúncia expressa a qualquer outro, por mais privilegiado que seja ou venha a ser.',
                  ],
                },
                {
                  text: '11.	XI– DA ASSINATURA DO LICENCIADO SOB A FORMA DE ACEITE ELETRÔNICO',
                  bold: true,
                },
                {
                  marginLeft: 23,
                  text: [
                    '11.1.	Estando o ',
                    {
                      text: 'LICENCIADO',
                      bold: true,
                    },
                    ' de acordo com os termos e condições do presente Contrato, fica o representante legal convidado a firmá-la, na forma do Termo de Aceite Eletrônico que segue, valendo, a partir de então, como aceitação a todos os termos do Contrato, com efeitos vinculativos às ',
                    {
                      text: 'PARTES',
                      bold: true,
                    },
                  ],
                },
              ],
              footer: (currentPage, pageCount) => ({
                layout: 'noBorders',
                margin: [14, 0, 14, 22],
                table: {
                  widths: ['auto'],
                  body: [
                    [
                      {
                        text: '_________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________',
                        alignment: 'center',
                        fontSize: 5,
                      },
                    ],
                    [
                      [
                        {
                          text: `Página ${currentPage.toString()} de ${pageCount}`,
                          fontSize: 7,
                          alignment: 'right',
                          /* horizontal, vertical */
                          margin: [3, 0],
                        },
                        {
                          text: '© IPAC PREMIUM',
                          fontSize: 7,
                          alignment: 'center',
                        },
                      ],
                    ],
                  ],
                },
              }),
              styles: {
                reportName: {
                  fontSize: 10,
                  bold: true,
                  alignment: 'center',
                  margin: [0, 4, 0, 0],
                },
                divider: {
                  fontSize: 11,
                  fillColor: '#F0F0F0',
                  alignment: 'center',
                  bold: true,
                },
                tableHeader: {
                  bold: true,
                  fontSize: 13,
                  color: 'black',
                },
                header: {
                  fontSize: 12,
                  bold: true,
                  margin: [0, 0, 0, 5],
                },
                columnsCoverages: {
                  lineHeight: 1.25,
                },
              },
              defaultStyle: {
                alignment: 'justify',
              },
            };
            const createdPdf = pdfMake.createPdf(document);
            setPdfFile(createdPdf);

            if (saveBlob) {
              createdPdf.getBlob((file) => {
                saveBlob(file);

                pdfDoneRef.current = true;
              });
            }
          };
          reader.readAsDataURL(blob);
        });
      // .catch((error) => console.error('Error loading image:', error));
    };

    convertImageToBase64();
  }, [
    discount_rate,
    address,
    cel,
    cep,
    cnpj,
    licenseAmountTotal,
    companyName,
    cpf,
    email,
    emailCompany,
    formatDate,
    license,
    name,
    valuePerMonth,
    totalValue,
    period,
    saveBlob,
    installments,
    installmentsExtense,
  ]);

  const generatePdf = useCallback(() => {
    // console.log(document);
    if (!pdfFile) return;

    pdfFile.open({}, window.open('', '_blank'));
    // .download('teste.pdf');
    //
  }, [pdfFile]);

  return (
    <button
      className="text-blue-500 font-bold ml-2"
      type="button"
      onClick={() => generatePdf()}
    >
      <div className="flex items-center">
        <div>
          <FiExternalLink className="mr-1" />
        </div>
        Contrato
      </div>
    </button>
  );
};

export default PDFTermsOfService;
