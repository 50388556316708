import React from 'react';
import { FaChevronDown } from 'react-icons/fa';
import { FiArrowDownCircle } from 'react-icons/fi';

import AccordionQuest from 'components/AccordionQuest';

const Questions: React.FC = () => (
  <section className="container-custom pt-20 lg:pt-40 pb-[127px] relative z-50 h-[1500px]">
    <div className="flex justify-center">
      <div className="bg-[#a3d8cf] w-[747px] h-[150px] md:rounded-full flex justify-center items-center border-[2px] border-black mb-10">
        <p className="text-center font-inter font-medium text-[#2C5282] text-[24px] sm:text-[50px] md:text-[60px]">
          Perguntas sobre o GDP
        </p>
      </div>
    </div>

    <div className="mb-10">
      <AccordionQuest question="O que é GDP ?">
        <p className="font-semibold">Resposta:</p>
        <p>
          - GDP é uma ferramenta de Gestão de Desempenho Profissional que além
          de auxiliar sua empresa da definição dos padrões de desempenho que se
          espera de cada funcionário conforme o cargo que ele ocupa, permite
          também o monitoramento e o registro constante de feedbacks.
        </p>
      </AccordionQuest>
    </div>

    <div className="mb-10">
      <AccordionQuest question="O que o GDP pode auxiliar com minha empresa ?">
        <p className="font-semibold">Resposta:</p>
        <p>
          - Evidenciar o que se espera de cada funcionário e o quanto cada um
          tem melhorado, deixando registrado de forma fácil e intuitiva todos os
          feedbacks e avaliações.
        </p>
        <p>
          - Que cada funcionário saiba o que se espera dele, como ele está sendo
          percebido por seu avaliador e em que ele deve melhorar
        </p>
        <p>
          - Definir dimensões de avaliação e os conceitos para cada cargo da sua
          empresa
        </p>
      </AccordionQuest>
    </div>

    <div className="mb-10">
      <AccordionQuest question="É preciso ter internet para acessar o MyIPAC ?">
        <p className="font-semibold">Resposta:</p>
        <p>
          - Sim, o sistema de GPD MyIPAC é preciso ter acesso a internet pois é
          100% online.
        </p>
      </AccordionQuest>
    </div>

    <div className="mb-10">
      <AccordionQuest question="Quais são as formas de pagamento disponiveis ?">
        <p className="font-semibold">Resposta:</p>
        <p>
          - Atualmente estamos apenas com pagamento em
          <b> cartão de credito</b>
          <span>, mas em breve teremos pagamentos em </span>
          <b>boleto e pix</b>
        </p>
      </AccordionQuest>
    </div>
    {/* <AccordionQuest question="O que é GDP ?">
      <p className="font-semibold">Resposta:</p>
      <p>
        - ipsum dolor sit amet consectetur. Orci enim ullamcorper amet arcu.
        Diam duis eget ac se mattis habitant venenatis enim enim.
      </p>
    </AccordionQuest> */}
  </section>
);

export default Questions;
